export default {
  envs: {
    production: {
      PROTO_FILE_URL: '/igb_js.proto',
      WEB_SOCKET_URL: 'wss://business.igrow.ai/ws'
    },
    development: {
      PROTO_FILE_URL: '/igb_camel_case_js.proto',
      WEB_SOCKET_URL: 'wss://business.igrow.ai/ws'
    },
    test: {}
  },
  apiTypes: {
    refreshConnection: 'RefreshConnection',
    login: 'Login',
    logout: 'Logout',
    signUp: 'SignUp',
    updateProfile: 'UpdateProfile',
    connectFbPage: 'ConnectFbPage',
    cancelConnectedFbPage: 'CancelConnectedFbPage',
    activateAccount: 'ActivateAccount',
    resendAccountActivationCode: 'ResendAccountActivationCode',
    fetchIgbUserMetric: 'FetchIgbUserMetric',
    fetchIgbUserFollowersHistory: 'FetchIgbUserFollowersHistory',
    fetchIgbMediaMetric: 'FetchIgbMediaMetric',
    fetchIgbMediaLikesHistory: 'FetchIgbMediaLikesHistory',
    fetchIgbMediaCommentsHistory: 'FetchIgbMediaCommentsHistory',
    fetchIgbMediaMostActionsList: 'FetchIgbMediaMostActionsList',
    fetchIgbStoryMetric: 'FetchIgbStoryMetric',
    fetchIgbStoryReachHistory: 'FetchIgbStoryReachHistory',
    fetchIgbStoryImpressionHistory: 'FetchIgbStoryImpressionHistory',
    sendAccountResetPasswordCode: 'SendAccountResetPasswordCode',
    resetAccountPassword: 'ResetAccountPassword'
  },
  mediaMostActionTypes: {
    unkown: 'Unkown',
    engaging: 'Engaging',
    liked: 'Liked',
    commented: 'Commented',
    recent: 'Recent'
  },
  mediaTypes: {
    none: 'PBIGBMediaTypeNone',
    image: 'PBIGBMediaTypeImage',
    video: 'PBIGBMediaTypeVideo',
    carouselAlbum: 'CarouselAlbum'
  }
}
