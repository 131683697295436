import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import './index.css'
import * as d3 from 'd3'
import Utils from '../../../services/Utils'
import useWindowResizeHandler from '../../hooks/useWindowResizeHandler'
import moment from 'moment'

export default (props) => {
  let margin = props.margin || { top: 95, right: 50, bottom: 30, left: 60 }
  const [height, setHeight] = useState(300)
  const [width, setWidth] = useState(300)

  const uniqueStr = Utils.generateRandomString()
  const chartId = `line-chart-${uniqueStr}`
  const sublineClassname = `visual-data-line-${uniqueStr}`
  const mousePerLineClassname = `mouse-per-line-${uniqueStr}`
  const mouseLineClassname = `mouse-line-${uniqueStr}`
  const leftStartX = margin.left - 20
  const tooltipWidthPlus5 = 140

  const targetRef = useRef()

  var formatTime = d3.timeFormat("%m/%d/%Y")

  const resetWidthAndHeight = () => {
    let dimensions = targetRef.current.getBoundingClientRect()
    setHeight(dimensions.height)
    setWidth(dimensions.width)
  }

  useLayoutEffect(resetWidthAndHeight, [targetRef.current])
  useWindowResizeHandler(resetWidthAndHeight)

  const chartTitle = (text) => {
    text.attr('x', leftStartX)
      .attr('y', ("45"))
      .attr('fill', '#2D3039')
      .style("font-size", "20px")
      .style("font-weight", "500")
      .text(props.title || "Test Title")
  }

  const instructionsText = (x, y, text, rectColor) => {
    return (g) => {
      let group = g.attr("transform", `translate(${x}, ${y})`)
        .attr("class", "instruction-text")

      group.append('rect')
        .attr("width", 16)
        .attr("height", 16)
        .attr("fill", rectColor)
        .attr("rx", 4)
        .attr("x", 0)
        .attr("y", 0)

      group.append('text')
        .style("font-size", "14px")
        .style("fill", "#878FA4")
        .text(text)
        .attr("x", 28)
        .attr("y", 14)
    }
  }

  const yAxis = (yScale, data) => {
    return (g) => {
      g.attr("transform", `translate(${margin.left},0)`)
        .attr("class", "y-axis")
        .call(d3.axisLeft(yScale).ticks(4).tickSize(-(width - margin.right - margin.left)))
        .call(g => g.select(".domain").remove())
        .call(g => {
          g.selectAll(".tick").filter((d, i) => { return i > 0 }).attr("stroke-dasharray", "2 1")
        })
        .call(g => g.select(".tick:last-of-type text").clone()
          .attr("x", 3)
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
          .text(data.y))
    }
  }

  const xAxis = (xScale) => {
    return (g) => {
      let axis = g.attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(xScale).ticks(7).tickSizeOuter(0).tickFormat(d3.timeFormat("%Y-%m-%d")))
      if (props.rotateTicksX) {
        return axis.selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", function (d) {
            return "rotate(-45)"
          })
      }
      return axis
    }
  }

  const dataLine = (data, xScale, yScale, strokeColor) => {
    let line = d3.line()
      .defined(d => !isNaN(Number(d.value)))
      .x(d => xScale(d.date))
      .y(d => yScale(Number(d.value)))

    return (path) => {
      path.datum(data)
        .attr("class", sublineClassname)
        .attr("fill", "none")
        .attr("stroke", strokeColor || '#0184FF')
        .attr("stroke-width", 3)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("d", line)
    }
  }

  const filledArea = (data, xScale, yScale) => {
    let area = d3.area()
      .x(d => xScale(d.date))
      .y0(height - margin.bottom)
      .y1(d => yScale(Number(d.value)))

    return (path) => {
      path.datum(data)
        .attr("class", "area")
        .attr("d", area)
    }
  }

  const mouseOverEffect = (data, xScale, yScale) => {
    return (g) => {
      let mouseG = g.attr("class", "mouse-over-effects")

      if (props.enableVerticalLine) {
        mouseG.append("path") // this is the black vertical line to follow mouse
          .attr("class", mouseLineClassname)
          .style("stroke", "#0184FF")
          .style("stroke-width", "2")
          .style("stroke-dasharray", "2 1")
          .style("opacity", "0")
      }

      var lines = document.getElementsByClassName(sublineClassname)

      var mousePerLine = mouseG.selectAll(`.${mousePerLineClassname}`)
        .data([{ values: data }])
        .enter()
        .append("g")
        .attr("class", mousePerLineClassname)


      let focusedPoint = props.point || {}
      mousePerLine.append("circle")
        .attr("r", 4)
        .style("stroke", function (d) {
          return focusedPoint.strokeColor || "#0184FF"
        })
        .style("fill", focusedPoint.fillColor || "#fff")
        .style("stroke-width", "3")
        .style("opacity", "0")

      if (props.pointSurounding) {
        mousePerLine.append("circle")
          .attr("r", 8)
          .style("stroke", function (d) {
            return props.pointSurounding.strokeColor || "#fff"
          })
          .style("stroke-opacity", props.pointSurounding.strokeOpacity || 0.5)
          .style("fill", "none")
          .style("stroke-width", "6")
          .style("opacity", "0")
      }

      d3.selectAll(`#${chartId} .line-tooltip`).remove()
      var tipDiv = d3.selectAll(`#${chartId}`).append("div")
        .attr("class", `line-tooltip ${props.alignment}`)
        .style("opacity", 0)

      var ttip1 = tipDiv.append('div').attr("class", "line-tooltip-first")
      var ttip2 = tipDiv.append('div').attr("class", "line-tooltip-second")
      var ttip3 = tipDiv.append('div').attr("class", "line-tooltip-third")

      // append a rect to catch mouse movements on canvas
      mouseG.append('svg:rect').attr('width', width - margin.left - margin.right) // can't catch mouse events on a g element
        .attr('height', height - margin.top - margin.bottom)
        .attr('x', margin.left)
        .attr('y', margin.top)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseout', function () { // on mouse out hide line, circles and tooltip
          d3.select(`.${mouseLineClassname}`)
            .style("opacity", "0")
          d3.selectAll(`.${mousePerLineClassname} circle`)
            .style("opacity", "0")
          tipDiv.style("opacity", 0).style("left", (-2000) + "px")
            .style("top", (-2000) + "px")
        })
        .on('mouseover', function () { // on mouse in show line, circles and tooltip
          d3.select(`.${mouseLineClassname}`)
            .style("opacity", "1")
          d3.selectAll(`.${mousePerLineClassname} circle`)
            .style("opacity", "1")
          tipDiv.style("opacity", 1)
        })
        .on('mousemove', function () { // mouse moving over canvas
          var mouse = d3.mouse(this)


          d3.selectAll(`.${mousePerLineClassname}`)
            .attr("transform", function (d, i) {
              let lastLine = lines[lines.length - 1]
              var beginning = 0,
                end = lastLine.getTotalLength(),
                pos


              while (true) {
                let target = Math.floor((beginning + end) / 2)
                pos = lastLine.getPointAtLength(target)
                if ((target === end || target === beginning) && pos.x !== mouse[0]) {
                  break
                }
                if (pos.x > mouse[0]) end = target
                else if (pos.x < mouse[0]) beginning = target
                else break //position found
              }

              let theDate = formatTime(xScale.invert(pos.x))
              let mDateMiliSecs = moment(theDate).add(8, 'h').unix() * 1000

              let theValue = data.filter((d) => { return d.date === mDateMiliSecs })[0]['value']

              let posyOfTarget = yScale(theValue)
              let posxOfTarget = xScale(mDateMiliSecs)

              tipDiv
                .style("opacity", 1)
                .style(`left`, `${posxOfTarget <= width - tooltipWidthPlus5 ? posxOfTarget + 5 : posxOfTarget - tooltipWidthPlus5}px`)
                .style("top", (posyOfTarget - 100) + "px")
              ttip1.html(`<div class='value-line'><div class='label'>${props.firstLabel ? `${props.firstLabel}: ` : ''}</div>${theValue}</div>`)
              ttip2.html(`<div class='value-line'><div class='label'>${props.secondLabel ? `${props.secondLabel}: ` : ''}</div>${props.averageValue || 'null'}</div>`)
              ttip3.html(theDate)

              d3.select(`.${mouseLineClassname}`)
                .attr("d", function () {
                  var d = "M" + posxOfTarget + "," + (height - margin.bottom)
                  d += " " + posxOfTarget + "," + posyOfTarget
                  return d
                })

              return "translate(" + posxOfTarget + "," + posyOfTarget + ")"
            })
        })
    }
  }

  const drawChart = (mainData, secondaryData) => {
    let x = d3.scaleTime()
      .domain(d3.extent(mainData, d => d.date))
      .range([margin.left, width - margin.right])

    let y = d3.scaleLinear()
      .domain([d3.min(mainData, d => Number(d.value)), d3.max(mainData, d => Number(d.value))]).nice()
      .range([height - margin.bottom, margin.top])

    let container = d3.select(`#${chartId}`)
    container.selectAll('svg').remove()

    let svg = container.append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("class", "line-chart-svg")

    svg.append("g").call(xAxis(x))

    svg.append("g").call(yAxis(y, mainData))

    svg.append("text").call(chartTitle)

    if (props.divideLine) {
      svg.append("line")
        .attr("x1", 0)
        .attr("y1", 70)
        .attr("x2", width)
        .attr("y2", 70)
        .attr("stroke", "#F4F7FC")
        .attr("stroke-width", 2)
    }

    if (secondaryData) {
      let secondaryLine = props.secondaryLine || {}
      if (props.divideLine) {
        let secondaryStartX = leftStartX + props.mainInstruction.width + 48
        svg.append("g").call(instructionsText(secondaryStartX, 100, props.secondaryInstruction.text, secondaryLine.strokeColor))
      }
      svg.append("path").call(dataLine(secondaryData, x, y, secondaryLine.strokeColor))
    }

    let mainLine = props.mainLine || {}
    if (props.divideLine) {
      svg.append("g").call(instructionsText(leftStartX, 100, props.mainInstruction.text, mainLine.strokeColor || '#0184FF'))
    }
    svg.append("path").call(dataLine(mainData, x, y, mainLine.strokeColor || '#0184FF'))



    if (props.withFilledArea) {
      svg.append("path").call(filledArea(mainData, x, y))
    }

    svg.append("g").call(mouseOverEffect(mainData, x, y))
  }

  useEffect(() => {
    drawChart(props.mainData, props.secondaryData)
  }, [props, height, width]) /* eslint-disable-line */

  return (
    <div id={chartId} ref={targetRef} className={`line-chart ${props.className || ''}`}></div>
  )
}
