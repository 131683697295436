export const analysisConstants = {
  USER_METRIC_REQUEST: 'ANALYSIS_USER_METRIC_REQUEST',
  USER_METRIC_SUCCESS: 'ANALYSIS_USER_METRIC_SUCCESS',
  USER_METRIC_FAILURE: 'ANALYSIS_USER_METRIC_FAILURE',

  USER_FOLLOWERS_HISTORY_REQUEST: 'ANALYSIS_USER_FOLLOWERS_HISTORY_REQUEST',
  USER_FOLLOWERS_HISTORY_SUCCESS: 'ANALYSIS_USER_FOLLOWERS_HISTORY_SUCCESS',
  USER_FOLLOWERS_HISTORY_FAILURE: 'ANALYSIS_USER_FOLLOWERS_HISTORY_FAILURE',

  MEDIA_METRIC_REQUEST: 'ANALYSIS_MEDIA_METRIC_REQUEST',
  MEDIA_METRIC_SUCCESS: 'ANALYSIS_MEDIA_METRIC_SUCCESS',
  MEDIA_METRIC_FAILURE: 'ANALYSIS_MEDIA_METRIC_FAILURE',

  MEDIA_LIKES_HISTORY_REQUEST: 'ANALYSIS_MEDIA_LIKES_HISTORY_REQUEST',
  MEDIA_LIKES_HISTORY_SUCCESS: 'ANALYSIS_MEDIA_LIKES_HISTORY_SUCCESS',
  MEDIA_LIKES_HISTORY_FAILURE: 'ANALYSIS_MEDIA_LIKES_HISTORY_FAILURE',

  MEDIA_COMMENTS_HISTORY_REQUEST: 'ANALYSIS_MEDIA_COMMENTS_HISTORY_REQUEST',
  MEDIA_COMMENTS_HISTORY_SUCCESS: 'ANALYSIS_MEDIA_COMMENTS_HISTORY_SUCCESS',
  MEDIA_COMMENTS_HISTORY_FAILURE: 'ANALYSIS_MEDIA_COMMENTS_HISTORY_FAILURE',

  MEDIA_MOST_ACTIONS_LIST_REQUEST: 'ANALYSIS_MEDIA_MOST_ACTIONS_LIST_REQUEST',
  MEDIA_MOST_ACTIONS_LIST_SUCCESS: 'ANALYSIS_MEDIA_MOST_ACTIONS_LIST_SUCCESS',
  MEDIA_MOST_ACTIONS_LIST_FAILURE: 'ANALYSIS_MEDIA_MOST_ACTIONS_LIST_FAILURE',

  STORY_METRIC_REQUEST: 'ANALYSIS_STORY_METRIC_REQUEST',
  STORY_METRIC_SUCCESS: 'ANALYSIS_STORY_METRIC_SUCCESS',
  STORY_METRIC_FAILURE: 'ANALYSIS_STORY_METRIC_FAILURE',

  STORY_REACH_HISTORY_REQUEST: 'ANALYSIS_STORY_REACH_HISTORY_REQUEST',
  STORY_REACH_HISTORY_SUCCESS: 'ANALYSIS_STORY_REACH_HISTORY_SUCCESS',
  STORY_REACH_HISTORY_FAILURE: 'ANALYSIS_STORY_REACH_HISTORY_FAILURE',

  STORY_IMPRESSION_HISTORY_REQUEST: 'ANALYSIS_STORY_IMPRESSION_HISTORY_REQUEST',
  STORY_IMPRESSION_HISTORY_SUCCESS: 'ANALYSIS_STORY_IMPRESSION_HISTORY_SUCCESS',
  STORY_IMPRESSION_HISTORY_FAILURE: 'ANALYSIS_STORY_IMPRESSION_HISTORY_FAILURE',
}
