import { globalErrorConstants } from '../_action_constants'
import { LOAD } from 'redux-storage'

export default function globalError(state = {
  isLoginRequired: false
}, action) {
  switch (action.type) {
    case LOAD:
      return {
        isLoginRequired: false
      }
    case globalErrorConstants.IS_LOGIN_REQUIRED:
      return {
        isLoginRequired: action.payload
      }
    default:
      return state
  }
}
