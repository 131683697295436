import { websocketConstants } from '../_action_constants';

const wsConnect = host => ({ type: websocketConstants.CONNECT, host });
const wsConnecting = host => ({ type: websocketConstants.CONNECTING, host });
const wsConnected = host => ({ type: websocketConstants.CONNECTED, host });
const wsDisconnect = host => ({ type: websocketConstants.DISCONNECT, host });
const wsDisconnected = host => ({ type: websocketConstants.DISCONNECTED, host });

export default {
    wsConnect,
    wsConnecting,
    wsConnected,
    wsDisconnect,
    wsDisconnected
};
