import React, { useState } from 'react';
import './index.css';
import './resp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {
  const [ isActive, setIsActive ] = useState(false);
  const [ selected, setSelected ] = useState(props.selected)

  const flipActiveState = () => {
    setIsActive(!isActive)
  }

  const generateDropdownItems = () => {
    const selectItem = (item) => {
      return () => {
        props.updateSelect(item)
        setSelected(item)
        setIsActive(false)
      }
    }

    return props.selections.map((s, i) => {
      return (
        <div key={i} onClick={selectItem(s)} href="#" className={`dropdown-item custom-item ${s.label === selected.label ? 'selected' : ''}`}>
          {s.label}
        </div>
      )
    })
  }

  const onMenuBlur = () => {
    setIsActive(false)
  }

  return (
    <div className={`dropdown ${isActive ? 'is-active' : ''}`}>
      <div className="dropdown-trigger">
        <button className={`button ${props.buttonClassname || ''}`} onClick={flipActiveState} aria-haspopup="true" aria-controls="dropdown-menu">
          <span className={`selected-text`}>{selected.label}</span>
          <span className="icon">
            <FontAwesomeIcon color='#617288' icon={'chevron-down'} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" onBlur={onMenuBlur} id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          { generateDropdownItems() }
        </div>
      </div>
    </div>
  )
}
