import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import localActions from '../../../_actions/local.actions';
import './index.css';
import './resp.css';
import ConfirmEmailImage from '../../../assets/images/confirm_email.svg';
import IgbButton from '../IgbButton';
import IgbIconButtonWrapper from '../IgbIconButtonWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from '../../../services/Constants';
import ResendEmailModal from '../ResendEmailModal';

export default (props) => {
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector(state => state.user)
  const dispatch = useDispatch();

  useEffect(() => {
    setIsPanelVisible(!user.account.isActivated)
  }, [user.account.isActivated])

  const closeConfirmPanel = () => {
    setIsPanelVisible(false)
    dispatch(localActions.updateEmailPanelState({isManuallyClosedEmailPanel: true}))
  }

  if (isPanelVisible) {
    return (
      <div className={`confirm-email-container ${props.className || ''}`}>
        <ResendEmailModal isModalOpen={isModalOpen} afterOpenModal={() => {}} closeModal={() => {setIsModalOpen(false)}} email={user.account && user.account.email} />
        <IgbIconButtonWrapper className='close' onClick={closeConfirmPanel}>
          <FontAwesomeIcon color='#617288' icon={'times'} />
        </IgbIconButtonWrapper>
        <div className='main-info'>
          <div className='title'>Please confirm your account</div>
          <div className='description'>{`You have not confirmed your account, this will bring some security risks. Please follow the instructions in the email we sent you to confirm your account.`}</div>
          <IgbButton className='igb-is-primary resend-email' onClick={() => {
              setIsModalOpen(true)
              dispatch({type: 'SEND_REQUEST', data: {}, apiType: Constants.apiTypes.resendAccountActivationCode})
            }}>Resend Email</IgbButton>
        </div>
        <img className='chat-image' src={ConfirmEmailImage} alt='a girl and a boy talk about work' />
      </div>
    )
  }

  return null
}
