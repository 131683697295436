import React from 'react';
import { Link } from "react-router-dom";
import './index.css';
import './resp.css';
import Logo from '../../../assets/images/logo_gray.svg';

export default (props) => {
  return (
    <div className={`start-page-container ${props.className || ''}`}>
      <div className='bg-cover'></div>
      <div className='content'>
        <div className='head'>
          <Link className='main-title' to='/'>
            <img className='logo' src={Logo} alt='logo' /><span className='logo-name'>Ig Analyzer Business</span>
          </Link> <span className='logo-name'>|</span> <span className='sub-title'>Instagram Data Analysis & Management</span>
        </div>
        { props.children }
      </div>
    </div>
  )
}
