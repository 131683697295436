import React from 'react'
import './index.css'
import './resp.css'
import Modal from 'react-modal'
import IgbButton from '../IgbButton'
import IgbIconButtonWrapper from '../IgbIconButtonWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClipLoader from "react-spinners/ClipLoader"

export default (props) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      padding: 0
    },
    overlay: {
      zIndex: 10,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  }


  Modal.setAppElement('#root')

  return (
    <Modal
      isOpen={props.isModalOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div className={`confirm-inner-container ${props.className || ''}`}>
        {
          props.hideCloseButton ? null :
            <IgbIconButtonWrapper className='close-icon' onClick={props.closeModal}>
              <FontAwesomeIcon color='#617288' icon={'times'} />
            </IgbIconButtonWrapper>
        }
        {props.children}
        <div className='action-group'>
          <IgbButton className='igb-is-primary action-button' onClick={props.primaryAction}>
            {
              props.isPrimaryLoading ? <ClipLoader
                size={30}
                color={"#FFF"}
                loading={true}
              /> : props.buttonTextPrimary
            }</IgbButton>
          <IgbButton className='igb-is-secondary action-button modal-secondary' onClick={props.secondaryAction}>{props.buttonTextSecondary}</IgbButton>
        </div>
      </div>
    </Modal>
  )
}
