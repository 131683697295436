import React from 'react';
import './index.css';
import './resp.css';
import IgbButton from '../../components/ui/IgbButton';
import StartingFrame from '../../components/ui/StartingFrame';

export default () => {
  return (
    <StartingFrame className='home-starting'>
      <div className='content-wrapper'>
        <div className='information'>
          <div className='sp-main'>Grow your brand presence on social media.</div>
          <div className='description'>Grow your social media presence with Ig Analyzer Business, the most powerful analytics & management platform for brands and agencies.</div>
          <div className='start-button-group'>
            <IgbButton isLink linkTo='sign_up' className='igb-is-primary start to-sign-up'>Sign up</IgbButton>
            <IgbButton isLink linkTo='login' className='igb-is-primary start to-sign-in'>Log in</IgbButton>
          </div>
        </div>
      </div>
    </StartingFrame>
  )
}
