import { websocketConstants } from '../_action_constants';

const websocketInitialState = {};

export default (state = { ...websocketInitialState }, action) => {
  switch (action.type) {
    case websocketConstants.CONNECT:
      return { ...state, host: action.host };
    default:
      return state;
  }
};
