import { userConstants } from '../_action_constants'

export default {
    signinRequest,
    signinSuccess,
    signinFailure,
    signupRequest,
    signupSuccess,
    signupFailure,
    updateProfileRequest,
    updateProfileSuccess,
    updateProfileFailure,
    connectFbRequest,
    connectFbSuccess,
    connectFbFailure,
    cancelConnectedFbPageRequest,
    cancelConnectedFbPageSuccess,
    cancelConnectedFbPageFailure,
    refreshConnectionRequest,
    refreshConnectionSuccess,
    refreshConnectionFailure,
    activateAccountRequest,
    activateAccountSuccess,
    activateAccountFailure,
    resendAccountActivationCodeRequest,
    resendAccountActivationCodeSuccess,
    resendAccountActivationCodeFailure,
    sendAccountResetPasswordCodeRequest,
    sendAccountResetPasswordCodeSuccess,
    sendAccountResetPasswordCodeFailure,
    resetAccountPasswordRequest,
    resetAccountPasswordSuccess,
    resetAccountPasswordFailure,
    logout
}

function signinRequest(requestData) { return { type: userConstants.SIGNIN_REQUEST, requestData } }
function signinSuccess(data) { return { type: userConstants.SIGNIN_SUCCESS, data } }
function signinFailure(error) { return { type: userConstants.SIGNIN_FAILURE, error } }

function signupRequest(requestData) { return { type: userConstants.SIGNUP_REQUEST, requestData } }
function signupSuccess(data) { return { type: userConstants.SIGNUP_SUCCESS, data } }
function signupFailure(error) { return { type: userConstants.SIGNUP_FAILURE, error } }

function updateProfileRequest(requestData) { return { type: userConstants.UPDATE_PROFILE_REQUEST, requestData } }
function updateProfileSuccess(data) { return { type: userConstants.UPDATE_PROFILE_SUCCESS, data } }
function updateProfileFailure(error) { return { type: userConstants.UPDATE_PROFILE_FAILURE, error } }

function connectFbRequest(requestData) { return { type: userConstants.CONNECT_FB_REQUEST, requestData } }
function connectFbSuccess(data) { return { type: userConstants.CONNECT_FB_SUCCESS, data } }
function connectFbFailure(error) { return { type: userConstants.CONNECT_FB_FAILURE, error } }

function cancelConnectedFbPageRequest(requestData) { return { type: userConstants.CANCEL_CONNECTED_FB_REQUEST, requestData } }
function cancelConnectedFbPageSuccess(data) { return { type: userConstants.CANCEL_CONNECTED_FB_SUCCESS, data } }
function cancelConnectedFbPageFailure(error) { return { type: userConstants.CANCEL_CONNECTED_FB_FAILURE, error } }

function refreshConnectionRequest(requestData) { return { type: userConstants.REFRESH_CONNECTION_REQUEST, requestData } }
function refreshConnectionSuccess(data) { return { type: userConstants.REFRESH_CONNECTION_SUCCESS, data } }
function refreshConnectionFailure(error) { return { type: userConstants.REFRESH_CONNECTION_FAILURE, error } }

function activateAccountRequest(requestData) { return { type: userConstants.ACTIVATE_ACCOUNT_REQUEST, requestData } }
function activateAccountSuccess(data) { return { type: userConstants.ACTIVATE_ACCOUNT_SUCCESS, data } }
function activateAccountFailure(error) { return { type: userConstants.ACTIVATE_ACCOUNT_FAILURE, error } }

function resendAccountActivationCodeRequest(requestData) { return { type: userConstants.RESEND_ACCOUNT_ACTIVATION_CODE_REQUEST, requestData } }
function resendAccountActivationCodeSuccess(data) { return { type: userConstants.RESEND_ACCOUNT_ACTIVATION_CODE_SUCCESS, data } }
function resendAccountActivationCodeFailure(error) { return { type: userConstants.RESEND_ACCOUNT_ACTIVATION_CODE_FAILURE, error } }

function sendAccountResetPasswordCodeRequest(requestData) { return { type: userConstants.SEND_ACCOUNT_RESET_PASSWORD_CODE_REQUEST, requestData } }
function sendAccountResetPasswordCodeSuccess(data) { return { type: userConstants.SEND_ACCOUNT_RESET_PASSWORD_CODE_SUCCESS, data } }
function sendAccountResetPasswordCodeFailure(error) { return { type: userConstants.SEND_ACCOUNT_RESET_PASSWORD_CODE_FAILURE, error } }

function resetAccountPasswordRequest(requestData) { return { type: userConstants.RESET_ACCOUNT_PASSWORD_REQUEST, requestData } }
function resetAccountPasswordSuccess(data) { return { type: userConstants.RESET_ACCOUNT_PASSWORD_SUCCESS, data } }
function resetAccountPasswordFailure(error) { return { type: userConstants.RESET_ACCOUNT_PASSWORD_FAILURE, error } }

function logout() {
    return { type: userConstants.LOGOUT }
}
