import React from 'react';
import { Helmet } from 'react-helmet-async'
import './index.css';
import './resp.css';
import LightPageWrapper from '../../components/ui/LightPageWrapper';
import IgbButton from '../../components/ui/IgbButton';

const paragraphs = [
  `This is the most recent terms of services agreements as of 2020. We reserve the right to change this policy at any time without notice, so please check it regularly.`,
  `Ig Analyzer Business does not share your credential with a third party, therefore, you need to use your login credentials to login to your applications and avail the services. Ig Analyzer Business will neither ask for your password nor use your credentials without your permission.`,
  `Please note that if you change your password, the script you set will automatically stop. To activate it again, you should re-launch the app and re-login with the new password. During such an event, the inactive period shall not be considered or added to your license.`,
  `Ig Analyzer Business’s system servers are secured using high level encryption to prevent unauthorized access of your account. However, in case of security breach and loss of data, we are not responsible for the consequences that follow the event.`,
  `Ig Analyzer Business is an entertainment service and we do not guarantee to give you 100% real reports. You accept that we do not guarantee to give you exact true reports by downloading and upgrading your membership. The content of the pages of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.`,
  `The Service, its original content, features and functionality will remain the exclusive property of Ig Analyzer Business and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.`
]

export default () => {
  const buildParagraphs = () => {
    return paragraphs.map((p, i) => {
      return (
        <div className='terms-paragraph' key={i}>
          {p}
        </div>
      )
    })
  }

  return (
    <LightPageWrapper withSignActions className='terms-page'>
      <Helmet>
        <title>Ig Analyzer Business, terms of use</title>
      </Helmet>
      <div className='terms-content-wrapper'>
        <div className='terms-container'>
          <div className='terms-title'>Terms of Use</div>
          {
            buildParagraphs()
          }
          <div className='terms-paragraph terms-footer'>© 2020 Ig Analyzer Business All rights reserved.</div>
        </div>
      </div>
      <div className='sign-button-group on-terms-actions'>
        <IgbButton isLink linkTo='sign_up' className='igb-is-primary start to-sign-up on-terms'>Sign Up</IgbButton>
        <IgbButton isLink linkTo='login' className='igb-is-primary start to-sign-in on-terms'>Log in</IgbButton>
      </div>
    </LightPageWrapper>
  )
}
