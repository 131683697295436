import React from 'react'
import './index.css'
import './resp.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IgbTooltip from '../IgbTooltip'
import Utils from '../../../services/Utils'
import { useSelector } from 'react-redux'

export default (props) => {
  const data = props.data || {
    name: 'Followers evolution',
    value: 3800,
    trending: 'desc',
    trendingValue: 200,
    trendingPercentage: 120
  }

  const analysis = useSelector(state => state.analysis)
  let loading = analysis.userMetricRequesting

  return (
    <div className={`fat-card-container ${loading ? 'pulse' : ''} ${props.className || ''}`}>
      {
        loading ? null :
          <React.Fragment>
            {props.helpText ? <IgbTooltip className='help-info'>{props.helpText}</IgbTooltip> : null}
            <div className='head'>
              <span className='name'>{data.name}</span>
            </div>
            <div className='body'>
              <span className='value'>{Utils.formatValue(data.value, 1)}</span>
            </div>
            <div className='foot'>
              {
                !props.noTrending ?
                  <div className='trends'>
                    {
                      data.trending ?
                        <div className={`value ${data.trending === 'desc' ? 'is-desc' : 'is-asc'}`}>
                          <FontAwesomeIcon className='symbol' icon={data.trending === 'desc' ? 'caret-down' : 'caret-up'} />
                          <div className='trending-summary'>
                            <span>{` ${Utils.formatValue(data.trendingValue, 1)}`}</span><div className='seperator'>|</div>{data.trendingPercentage}%
                        </div>
                        </div> :
                        <div className='value none-trending'><span className='symbol'>-</span>N/A</div>
                    }
                  </div> : <div></div>
              }
              <img src={props.catIcon} alt='' />
            </div>
          </React.Fragment>
      }
    </div>
  )
}
