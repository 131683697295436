export const userConstants = {
    SIGNUP_REQUEST: 'USER_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'USER_SIGNUP_FAILURE',

    SIGNIN_REQUEST: 'USER_SIGNIN_REQUEST',
    SIGNIN_SUCCESS: 'USER_SIGNIN_SUCCESS',
    SIGNIN_FAILURE: 'USER_SIGNIN_FAILURE',

    UPDATE_PROFILE_REQUEST: 'USER_UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'USER_UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'USER_UPDATE_PROFILE_FAILURE',

    CONNECT_FB_REQUEST: 'USER_CONNECT_FB_REQUEST',
    CONNECT_FB_SUCCESS: 'USER_CONNECT_FB_SUCCESS',
    CONNECT_FB_FAILURE: 'USER_CONNECT_FB_FAILURE',

    CANCEL_CONNECTED_FB_REQUEST: 'USER_CANCEL_CONNECTED_FB_REQUEST',
    CANCEL_CONNECTED_FB_SUCCESS: 'USER_CANCEL_CONNECTED_FB_SUCCESS',
    CANCEL_CONNECTED_FB_FAILURE: 'USER_CANCEL_CONNECTED_FB_FAILURE',

    REFRESH_CONNECTION_REQUEST: 'USER_REFRESH_CONNECTION_REQUEST',
    REFRESH_CONNECTION_SUCCESS: 'USER_REFRESH_CONNECTION_SUCCESS',
    REFRESH_CONNECTION_FAILURE: 'USER_REFRESH_CONNECTION_FAILURE',

    ACTIVATE_ACCOUNT_REQUEST: 'USER_ACTIVATE_ACCOUNT_REQUEST',
    ACTIVATE_ACCOUNT_SUCCESS: 'USER_ACTIVATE_ACCOUNT_SUCCESS',
    ACTIVATE_ACCOUNT_FAILURE: 'USER_ACTIVATE_ACCOUNT_FAILURE',

    RESEND_ACCOUNT_ACTIVATION_CODE_REQUEST: 'USER_RESEND_ACCOUNT_ACTIVATION_CODE_REQUEST',
    RESEND_ACCOUNT_ACTIVATION_CODE_SUCCESS: 'USER_RESEND_ACCOUNT_ACTIVATION_CODE_SUCCESS',
    RESEND_ACCOUNT_ACTIVATION_CODE_FAILURE: 'USER_RESEND_ACCOUNT_ACTIVATION_CODE_FAILURE',

    SEND_ACCOUNT_RESET_PASSWORD_CODE_REQUEST: 'USER_SEND_ACCOUNT_RESET_PASSWORD_CODE_REQUEST',
    SEND_ACCOUNT_RESET_PASSWORD_CODE_SUCCESS: 'USER_SEND_ACCOUNT_RESET_PASSWORD_CODE_SUCCESS',
    SEND_ACCOUNT_RESET_PASSWORD_CODE_FAILURE: 'USER_SEND_ACCOUNT_RESET_PASSWORD_CODE_FAILURE',

    RESET_ACCOUNT_PASSWORD_REQUEST: 'USER_RESET_ACCOUNT_PASSWORD_REQUEST',
    RESET_ACCOUNT_PASSWORD_SUCCESS: 'USER_RESET_ACCOUNT_PASSWORD_SUCCESS',
    RESET_ACCOUNT_PASSWORD_FAILURE: 'USER_RESET_ACCOUNT_PASSWORD_FAILURE',

    LOGOUT: 'USER_LOGOUT'
};
