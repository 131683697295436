import { localConstants } from '../_action_constants';

export default {
  updateEmailPanelState,
  selectProfile,
  setDateRange
}

export function updateEmailPanelState(payload) { return { type: localConstants.UPDATE_EMAIL_PANEL_STATE, payload } }

export function selectProfile(payload) { return { type: localConstants.SELECT_PROFILE, payload } }

export function setDateRange(payload) { return { type: localConstants.SET_DATE_RANGE, payload } }
