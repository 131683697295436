import React from 'react';

const locales ={
  en: {
    pages: {
      overview: {
        title: 'Analytics Overview'
      },
      engagement: {
        title: 'Engagement'
      },
      stories: {
        title: 'Stories'
      },
      settings: {
        title: 'Settings'
      },
      signup: {
        title: 'Sign Up'
      },
      login: {
        title: 'Log in',
        emailLabel: 'Your email',
        passwordLabel: 'Password'
      }
    }
  },
  zh: {
    pages: {
      overview: {
        title: '概览'
      },
      engagement: {
        title: '订阅'
      },
      stories: {
        title: '故事'
      },
      settings: {
        title: '设置'
      },
      signup: {
        title: '注册'
      },
      login: {
        title: '登录'
      }
    }
  }
};

export default locales;

const LocaleContext = React.createContext(locales['en']);

export {
  locales,
  LocaleContext
}
