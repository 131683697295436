import { userConstants } from '../_action_constants'
import { LOAD } from 'redux-storage'

export default function user(state = {}, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        ...action.payload.user,
        signInError: null,
        signUpError: null,
        updateProfileError: null,
        connetFbError: null,
        refreshConnectionError: null,
        accountActivationError: null,
        resetPasswordCodeError: null,
        resetPasswordError: null,
        signingUp: false,
        loggingIn: false,
        profileUpdating: false,
        activationCodeResending: false,
        fbConnecting: false,
        connectedFbCanceling: false,
        connectionRefreshing: false,
        accountActivating: false,
        resetPasswordEmailSending: false,
        passwordResetting: false,
        profileUpdateSuccessMsg: null
      }
    case userConstants.SIGNIN_REQUEST:
      return {
        loggingIn: true
      }
    case userConstants.SIGNIN_SUCCESS:
      return {
        loggedIn: true,
        account: action.data.account,
        accessToken: action.data.token,
        fbConnected: action.data.account.fbPages && (action.data.account.fbPages.length > 0)
      }
    case userConstants.SIGNIN_FAILURE:
      return {
        signInError: action.error
      }
    case userConstants.LOGOUT:
      return {}
    case userConstants.SIGNUP_REQUEST:
      return {
        signingUp: true
      }
    case userConstants.SIGNUP_SUCCESS:
      return {
        loggedIn: true,
        signingUp: false,
        account: action.data.account,
        accessToken: action.data.token,
        fbConnected: action.data.account.fbPages && (action.data.account.fbPages.length > 0)
      }
    case userConstants.SIGNUP_FAILURE:
      return {
        signUpError: action.error
      }
    case userConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        profileUpdating: true,
        profileUpdateSuccessMsg: null
      }
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profileUpdating: false,
        accessToken: action.data.token,
        profileUpdateSuccessMsg: 'Your change has been successfully saved!'
      }
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        profileUpdating: false,
        updateProfileError: action.error,
        profileUpdateSuccessMsg: null
      }
    case userConstants.RESEND_ACCOUNT_ACTIVATION_CODE_REQUEST:
      return {
        ...state,
        activationCodeResending: true
      }
    case userConstants.RESEND_ACCOUNT_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        activationCodeResending: false
      }
    case userConstants.RESEND_ACCOUNT_ACTIVATION_CODE_FAILURE:
      return {
        ...state,
        activationCodeResending: false
      }
    case userConstants.CONNECT_FB_REQUEST:
      return {
        ...state,
        fbConnecting: true
      }
    case userConstants.CONNECT_FB_SUCCESS:
      return {
        ...state,
        fbConnecting: false,
        account: action.data.account,
        fbConnected: action.data.account.fbPages && (action.data.account.fbPages.length > 0)
      }
    case userConstants.CONNECT_FB_FAILURE:
      return {
        ...state,
        fbConnecting: false,
        connetFbError: action.error
      }
    case userConstants.CANCEL_CONNECTED_FB_REQUEST:
      return {
        ...state,
        connectedFbCanceling: true
      }
    case userConstants.CANCEL_CONNECTED_FB_SUCCESS:
      return {
        ...state,
        account: action.data.account,
        fbConnected: action.data.account.fbPages && (action.data.account.fbPages.length > 0),
        connectedFbCanceling: false
      }
    case userConstants.CANCEL_CONNECTED_FB_FAILURE:
      return {
        ...state,
        connectedFbCanceling: false,
        cancelConnectedFbError: action.error
      }
    case userConstants.REFRESH_CONNECTION_REQUEST:
      return {
        ...state,
        connectionRefreshing: true,
      }
    case userConstants.REFRESH_CONNECTION_SUCCESS:
      return {
        ...state,
        connectionRefreshing: false,
        account: action.data.account,
        fbConnected: action.data.account.fbPages && (action.data.account.fbPages.length > 0),
        accessToken: action.data.token
      }
    case userConstants.REFRESH_CONNECTION_FAILURE:
      return {
        ...state,
        connectionRefreshing: false,
        refreshConnectionError: action.error
      }
    case userConstants.ACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        accountActivating: true,
        accountActivationError: null,
        activatedEmail: null
      }
    case userConstants.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountActivating: false,
        activatedEmail: action.data.email,
        account: action.data.account,
        fbConnected: action.data.account.fbPages && (action.data.account.fbPages.length > 0),
      }
    case userConstants.ACTIVATE_ACCOUNT_FAILURE:
      return {
        ...state,
        accountActivating: null,
        accountActivationError: action.error
      }
    case userConstants.SEND_ACCOUNT_RESET_PASSWORD_CODE_REQUEST:
      return {
        ...state,
        resetPasswordEmailSending: true,
        resetPasswordCodeError: null
      }
    case userConstants.SEND_ACCOUNT_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        resetPasswordEmailSending: false,
        resetPasswordCodeError: null
      }
    case userConstants.SEND_ACCOUNT_RESET_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        resetPasswordEmailSending: null,
        resetPasswordCodeError: action.error
      }
    case userConstants.RESET_ACCOUNT_PASSWORD_REQUEST:
      return {
        ...state,
        passwordResetting: true,
        resetPasswordError: null
      }
    case userConstants.RESET_ACCOUNT_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetting: false,
        resetPasswordError: null
      }
    case userConstants.RESET_ACCOUNT_PASSWORD_FAILURE:
      return {
        ...state,
        passwordResetting: null,
        resetPasswordError: action.error
      }
    default:
      return state
  }
}
