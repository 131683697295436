import React, { useState } from 'react';
import './index.css';
import './resp.css';
import { useDispatch } from 'react-redux';
import Constants from '../../../services/Constants';
import AddProfileModal from '../AddProfileModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const dispatch = useDispatch()

  const connectFbCallback = (res) => {
    if (res.authResponse) {
      dispatch({type: 'SEND_REQUEST', data: {fbPageAccessToken: res.authResponse.accessToken}, apiType: Constants.apiTypes.connectFbPage})
    }
    closeModal();
  }

  const addMoreProfile = () => {
    setIsModalOpen(true)
    if (props.callback) {
      props.callback()
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div style={{width: '100%'}}>
      <AddProfileModal fbLoginCallback={connectFbCallback} isModalOpen={isModalOpen} afterOpenModal={() => {}} closeModal={closeModal} />
      <div className={`add-profile-wrapper ${props.className || ''}`} onClick={addMoreProfile}>
        <div className='add-more-profile'>
          <FontAwesomeIcon className='icon' color='#0184FF' icon={'plus'} />
        </div>
        <div className='text'>Add Social Profiles</div>
      </div>
    </div>
  )
}
