import React from 'react';
import './index.css';

export default (props) => {
  return (
    <div className={`igb-icon-button ${props.className || ''}`} onClick={props.onClick}>
      {props.children}
    </div>
  )
}
