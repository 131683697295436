import React from 'react';
import './index.css';
import HappyBoy from '../../../assets/images/happy-boy.png';
import AddIcon from '../../../assets/images/add-icon.png';
import UnHappyBoy from '../../../assets/images/unhappy-boy.png';
import MinusIcon from '../../../assets/images/minus-icon.png';

export default (props) => {
  return (
    <div className='avatar'>
      <img className='boy-avatar' src={props.type === 'add' ? HappyBoy : UnHappyBoy} alt='cartoon boy smile' />
      <img className='action-badge' src={props.type === 'add' ? AddIcon : MinusIcon} alt='add' />
    </div>
  )
}
