import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import './resp.css';
import ResetpwBgDots from '../../../assets/images/resetpw_bg_dots.svg';
import ResetpwBgRock1 from '../../../assets/images/resetpw_bg_rock1.svg';
import ResetpwBgRock2 from '../../../assets/images/resetpw_bg_rock2.svg';
import LogoBlue from '../../../assets/images/logo_blue.svg';
import IgbButton from '../IgbButton';

export default (props) => {
  const { withSignActions } = props;

  return (
    <div className={`light-page-wrapper ${props.className || ''} ${withSignActions && 'with-sign-actions'}`}>
      <img className='background-images top-dots' alt='' src={ResetpwBgDots} />
      <img className='background-images bottom-dots' alt='' src={ResetpwBgDots} />
      <img className='background-images top-rock' alt='' src={ResetpwBgRock1} />
      <img className='background-images bottom-rock' alt='' src={ResetpwBgRock2} />
      <div className='header'>
        <div className='brand'>
          <Link to={`/`} className='link'>
            <img className='logo' src={LogoBlue} alt='' />
            <span className='main-title'>Ig Analyzer Business</span>
          </Link>
          <span className={`divider ${withSignActions && 'with-sign-actions'}`}>|</span>
          <span className={`sub-title ${withSignActions && 'with-sign-actions'}`}>Instagram Data Analysis & Management</span>
        </div>
        {
          withSignActions ?
          <div className='start-button-group'>
            <IgbButton isLink linkTo='login' className='igb-is-primary start to-sign-in'>Log in</IgbButton>
            <IgbButton isLink linkTo='sign_up' className='igb-is-primary start to-sign-up'>Sign up</IgbButton>
          </div> : null
        }
      </div>
      { props.children }
    </div>
  )
}
