import Constants from '../services/Constants';
import userActions from '../_actions/user.actions';
import analysisActions from '../_actions/analysis.actions';

export default {
  requesting: (apiType, requestData) => {
    switch (apiType) {
      case Constants.apiTypes.login:
        return userActions.signinRequest(requestData)
      case Constants.apiTypes.logout:
        return userActions.logout()
      case Constants.apiTypes.signUp:
        return userActions.signupRequest(requestData)
      case Constants.apiTypes.updateProfile:
        return userActions.updateProfileRequest(requestData)
      case Constants.apiTypes.activateAccount:
        return userActions.activateAccountRequest(requestData)
      case Constants.apiTypes.resendAccountActivationCode:
        return userActions.resendAccountActivationCodeRequest(requestData)
      case Constants.apiTypes.connectFbPage:
        return userActions.connectFbRequest(requestData)
      case Constants.apiTypes.cancelConnectedFbPage:
        return userActions.cancelConnectedFbPageRequest(requestData)
      case Constants.apiTypes.refreshConnection:
        return userActions.refreshConnectionRequest(requestData)
      case Constants.apiTypes.sendAccountResetPasswordCode:
        return userActions.sendAccountResetPasswordCodeRequest(requestData)
      case Constants.apiTypes.resetAccountPassword:
        return userActions.resetAccountPasswordRequest(requestData)
      case Constants.apiTypes.fetchIgbUserMetric:
        return analysisActions.userMetricRequest(requestData)
      case Constants.apiTypes.fetchIgbUserFollowersHistory:
        return analysisActions.userFollowersHistoryRequest(requestData)
      case Constants.apiTypes.fetchIgbMediaMetric:
        return analysisActions.mediaMetricRequest(requestData)
      case Constants.apiTypes.fetchIgbMediaLikesHistory:
        return analysisActions.mediaLikesHistoryRequest(requestData)
      case Constants.apiTypes.fetchIgbMediaCommentsHistory:
        return analysisActions.mediaCommentHistoryRequest(requestData)
      case Constants.apiTypes.fetchIgbMediaMostActionsList:
        return analysisActions.mediaMostActionsListRequest(requestData)
      case Constants.apiTypes.fetchIgbStoryMetric:
        return analysisActions.storyMetricRequest(requestData)
      case Constants.apiTypes.fetchIgbStoryReachHistory:
        return analysisActions.storyReachHistoryRequest(requestData)
      case Constants.apiTypes.fetchIgbStoryImpressionHistory:
        return analysisActions.storyImpressionHistoryRequest(requestData)
      default:
        return {}
    }
  },
  requestSuccess: (apiType, data) => {
    switch (apiType) {
      case Constants.apiTypes.login:
        return userActions.signinSuccess(data)
      case Constants.apiTypes.logout:
        return userActions.logout()
      case Constants.apiTypes.signUp:
        return userActions.signupSuccess(data)
      case Constants.apiTypes.updateProfile:
        return userActions.updateProfileSuccess(data)
      case Constants.apiTypes.activateAccount:
        return userActions.activateAccountSuccess(data)
      case Constants.apiTypes.resendAccountActivationCode:
        return userActions.resendAccountActivationCodeSuccess(data)
      case Constants.apiTypes.connectFbPage:
        return userActions.connectFbSuccess(data)
      case Constants.apiTypes.cancelConnectedFbPage:
        return userActions.cancelConnectedFbPageSuccess(data)
      case Constants.apiTypes.refreshConnection:
        return userActions.refreshConnectionSuccess(data)
      case Constants.apiTypes.sendAccountResetPasswordCode:
        return userActions.sendAccountResetPasswordCodeSuccess(data)
      case Constants.apiTypes.resetAccountPassword:
        return userActions.resetAccountPasswordSuccess(data)
      case Constants.apiTypes.fetchIgbUserMetric:
        return analysisActions.userMetricSuccess(data)
      case Constants.apiTypes.fetchIgbUserFollowersHistory:
        return analysisActions.userFollowersHistorySuccess(data)
      case Constants.apiTypes.fetchIgbMediaMetric:
        return analysisActions.mediaMetricSuccess(data)
      case Constants.apiTypes.fetchIgbMediaLikesHistory:
        return analysisActions.mediaLikesHistorySuccess(data)
      case Constants.apiTypes.fetchIgbMediaCommentsHistory:
        return analysisActions.mediaCommentHistorySuccess(data)
      case Constants.apiTypes.fetchIgbMediaMostActionsList:
        return analysisActions.mediaMostActionsListSuccess(data)
      case Constants.apiTypes.fetchIgbStoryMetric:
        return analysisActions.storyMetricSuccess(data)
      case Constants.apiTypes.fetchIgbStoryReachHistory:
        return analysisActions.storyReachHistorySuccess(data)
      case Constants.apiTypes.fetchIgbStoryImpressionHistory:
        return analysisActions.storyImpressionHistorySuccess(data)
      default:
        return {}
    }
  },
  requestFailure: (apiType, error) => {
    switch (apiType) {
      case Constants.apiTypes.login:
        return userActions.signinFailure(error)
      case Constants.apiTypes.logout:
        return userActions.logout()
      case Constants.apiTypes.signUp:
        return userActions.signupFailure(error)
      case Constants.apiTypes.updateProfile:
        return userActions.updateProfileFailure(error)
      case Constants.apiTypes.activateAccount:
        return userActions.activateAccountFailure(error)
      case Constants.apiTypes.resendAccountActivationCode:
        return userActions.resendAccountActivationCodeFailure(error)
      case Constants.apiTypes.connectFbPage:
        return userActions.connectFbFailure(error)
      case Constants.apiTypes.cancelConnectedFbPage:
        return userActions.cancelConnectedFbPageFailure(error)
      case Constants.apiTypes.refreshConnection:
        return userActions.refreshConnectionFailure(error)
      case Constants.apiTypes.sendAccountResetPasswordCode:
        return userActions.sendAccountResetPasswordCodeFailure(error)
      case Constants.apiTypes.resetAccountPassword:
        return userActions.resetAccountPasswordFailure(error)
      case Constants.apiTypes.fetchIgbUserMetric:
        return analysisActions.userMetricFailure(error)
      case Constants.apiTypes.fetchIgbUserFollowersHistory:
        return analysisActions.userFollowersHistoryFailure(error)
      case Constants.apiTypes.fetchIgbMediaMetric:
        return analysisActions.mediaMetricFailure(error)
      case Constants.apiTypes.fetchIgbMediaLikesHistory:
        return analysisActions.mediaLikesHistoryFailure(error)
      case Constants.apiTypes.fetchIgbMediaCommentsHistory:
        return analysisActions.mediaCommentHistoryFailure(error)
      case Constants.apiTypes.fetchIgbMediaMostActionsList:
        return analysisActions.mediaMostActionsListFailure(error)
      case Constants.apiTypes.fetchIgbStoryMetric:
        return analysisActions.storyMetricFailure(error)
      case Constants.apiTypes.fetchIgbStoryReachHistory:
        return analysisActions.storyReachHistoryFailure(error)
      case Constants.apiTypes.fetchIgbStoryImpressionHistory:
        return analysisActions.storyImpressionHistoryFailure(error)
      default:
        return {}
    }
  }
}
