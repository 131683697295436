import moment from 'moment'

const getTrending = (t) => {
  return t.hasOwnProperty('isTrendingUp') ? (t.isTrendingUp ? 'asc' : 'desc') : null
}

export default {
  generateRandomString: () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  },
  uuidv4: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)  /* eslint-disable-line */
      return v.toString(16)
    })
  },
  camelize: (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    }).replace(/\s+/g, '')
  },
  formatValue: (num, digits) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
  },
  cacheUserInfo: ({ account, token }) => {
    let accountStr = JSON.stringify(account)
    localStorage.setItem('user_account', accountStr)
    localStorage.setItem('access_token', token)
  },
  getCachedToken: () => {
    return localStorage.getItem('access_token')
  },
  getCachedAccount: () => {
    let accountStr = localStorage.getItem('user_account')
    return JSON.parse(accountStr)
  },
  getDefaultStartDate: () => { return moment().subtract(30, 'd').utc() },
  getDefaultEndDate: () => { return moment().subtract(1, 'd').utc() },
  buildCardData: (source, cardName) => {
    return {
      name: cardName,
      value: source ? source.value : 0,
      trending: getTrending(source || {}),
      trendingValue: source ? source.trendingValue : 0,
      trendingPercentage: source ? (source.trendingPercentage * 100).toFixed(2) : 0
    }
  },
  getDurationText: (timestamp) => {
    let now = moment()
    const { _data } = moment.duration(now.diff(moment(timestamp * 1000)))
    if (_data.years > 0) {
      return _data.years + ` ${_data.years === 1 ? 'year' : 'years'} `
    }
    if (_data.months > 0) {
      return _data.months + ` ${_data.days === 1 ? 'month' : 'months'} `
    }
    if (_data.days > 0) {
      return _data.days + ` ${_data.days === 1 ? 'day' : 'days'} `
    }
    if (_data.hours > 0) {
      return _data.hours + ` ${_data.hours === 1 ? 'hour' : 'hours'} `
    }
    if (_data.minutes > 0) {
      return _data.minutes + ` ${_data.minutes === 1 ? 'minute' : 'minutes'} `
    }
    return _data.seconds + ` ${_data.seconds === 1 ? 'second' : 'seconds'} `
  },
  buildBaseParams: (storeLocal, storeUser) => {
    let { fbPages } = storeUser.account
    if (fbPages && fbPages.length > 0) {
      let selectedPage = storeLocal.selectedPage || fbPages[0]
      return {
        igbUserId: selectedPage.igbUser.igbId,
        startDate: storeLocal.dateRange.sDate.unix(),
        endDate: storeLocal.dateRange.eDate.unix()
      }
    }
    return null
  }
}
