import React, { useState, useEffect } from 'react';
import './index.css';
import './react_dates_overrides.css';
import './resp.css';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import Utils from '../../../services/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMediaQuery } from 'react-responsive'

const DEFAULT_RANGE = {label: 'Custom'}
const START_DATE = "startDate";
// const END_DATE = "endDate";

export default (props) => {
  const [ startDate, setStartDate ] = useState(props.startDate || Utils.getDefaultStartDate());
  const [ endDate, setEndDate ] = useState(props.endDate || Utils.getDefaultEndDate());
  const [ selectedRange, setSelectedRange ] = useState(DEFAULT_RANGE);
  const [ focusedInput, setFocusedInput ] = useState(START_DATE);
  const [ isRangesVisible, setIsRangesVisible ] = useState(false);
  const [ isCalendarVisible, setIsCalendarVisible ] = useState(false);

  const isMobileDevice = useMediaQuery({query: '(max-width: 480px)'})

  useEffect(() => {
    setIsRangesVisible(!isMobileDevice)
  }, [isMobileDevice])

  const predefinedRanges = [
    {
      label: 'Last 7 days',
      getStartDate: () => {
        return moment().subtract(7, 'd').utc()
      },
      getEndDate: () => {
        return moment().subtract(1, 'd').utc()
      }
    },
    {
      label: 'Last 30 days',
      getStartDate: () => {
        return moment().subtract(30, 'd').utc()
      },
      getEndDate: () => {
        return moment().subtract(1, 'd').utc()
      }
    },
    {
      label: 'Last 3 months',
      getStartDate: () => {
        return moment().subtract(3, 'M').utc()
      },
      getEndDate: () => {
        return moment().utc()
      }
    },
    {
      label: 'Previous week',
      getStartDate: () => {
        return moment().startOf('week').subtract(1, 'w').utc()
      },
      getEndDate: () => {
        return moment().startOf('week').utc()
      }
    },
    {
      label: 'Previous month',
      getStartDate: () => {
        return moment().startOf('month').subtract(1, 'M').utc()
      },
      getEndDate: () => {
        return moment().startOf('month').utc()
      }
    },
    {
      label: 'Previous year',
      getStartDate: () => {
        return moment().startOf('year').subtract(1, 'y').utc()
      },
      getEndDate: () => {
        return moment().startOf('year').utc()
      }
    },
    {
      label: 'This year',
      getStartDate: () => {
        return moment().startOf('year').utc()
      },
      getEndDate: () => {
        return moment().utc()
      }
    },
    DEFAULT_RANGE
  ]

  const datesChangeHandler = ({startDate, endDate}) => {
    setStartDate(startDate? startDate.utc() : null)
    setEndDate(endDate? endDate.utc() : null)
    setSelectedRange(DEFAULT_RANGE)
  }

  const focusChangeHandler = (focusedInput) => {
    setFocusedInput(!focusedInput ? START_DATE : focusedInput)

  }

  const selectRange = (range) => {
    return () => {
      setSelectedRange(range)
      if (isMobileDevice) {
        setIsRangesVisible(false)
      }
      if (range.label === 'Custom') {
        setStartDate(null)
        setEndDate(null)
      } else {
        setStartDate(range.getStartDate())
        setEndDate(range.getEndDate())
      }
    }
  }

  const buildCalendarInfo = () => {
    return (
      <div className='ranges-container'>
        {
          isRangesVisible ?
          <div className='defined-ranges'>
            {
              predefinedRanges.map((pr, i) => {
                return (
                  <div key={i} className={`defined-range-item ${pr.label === selectedRange.label ? 'active' : ''}`} onClick={selectRange(pr)}>
                    {pr.label}
                  </div>
                )
              })
            }
          </div> : null
        }
        {
          isMobileDevice ?
            <button className='selected-range' onClick={() => {setIsRangesVisible(!isRangesVisible)}}>
              Custom
              {isRangesVisible ? <FontAwesomeIcon className='right-arrow' color='#4A4A4A' icon={'chevron-up'} /> : <FontAwesomeIcon className='right-arrow' color='#4A4A4A' icon={'chevron-down'} />}
            </button> : null
        }
      </div>
    )
  }

  const generateDateRangeString = () => {
    const startDateString = startDate && startDate.format("YYYY/MM/DD");
    const endDateString = endDate && endDate.format("YYYY/MM/DD");

    return startDate && endDate
        ? <div className='range-value'>{`${startDateString} - ${endDateString}`}</div>
        : <div className='range-placeholder'>Choose a date range</div>;
  }

  const onDatesSelected = () => {
    setIsCalendarVisible(false)
    props.updateDates(startDate || '', endDate || '')
  }

  return (
    <div className='range-picker-container'>
      <div className='range-picker-input' onClick={() => { setIsCalendarVisible(!isCalendarVisible) }}>
        <div className='range-string'>
          {generateDateRangeString()}
        </div>
        <FontAwesomeIcon icon={'chevron-down'} color={'#818E94'} />
      </div>

      {
        isCalendarVisible ?
        <div className='calendar-container'>
          <DayPickerRangeController
            keepOpenOnDateSelect={false}
            enableOutsideDays={false}
            onOutsideClick={onDatesSelected}
            startDate={startDate} // momentPropTypes.momentObj or null,
            endDate={endDate} // momentPropTypes.momentObj or null,
            onDatesChange={datesChangeHandler} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusChangeHandler} // PropTypes.func.isRequired,
            renderCalendarInfo={buildCalendarInfo}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            calendarInfoPosition={!isMobileDevice ? 'after' : 'bottom'}
            minDate={moment().subtract(1, 'y').startOf('year')}
            maxDate={moment()}
            isOutsideRange={(d) => {return moment(d) > moment().add(1, 'd')}}
            />
        </div> : null
      }
    </div>
  )
}
