import Protobuf from 'protobufjs';
import * as Long from 'long';
import Constants from '../services/Constants';
import Utils from '../services/Utils';

const proto_file_url = Constants.envs[process.env.NODE_ENV].PROTO_FILE_URL;
Protobuf.util.Long = Long;
Protobuf.configure();

const getResponseObject = (data) => {
  return new Promise((resolve, reject) => {
    Protobuf.load(proto_file_url, (err, root) => {
      if (err) {
        reject(err)
        return
      }

      const { PBMessage, PBErrorCode } = root;
      data.arrayBuffer().then((buffer) => {
        let uint8array = new Uint8Array(buffer)
        const pb_message = PBMessage.decode(uint8array);

        var pbMessageObject = PBMessage.toObject(pb_message, {
          enums: String,  // enums as string names
          longs: String,  // longs as strings (requires long.js)
          bytes: String,  // bytes as base64 encoded strings
          defaults: true, // includes default values
          arrays: true,   // populates empty arrays (repeated fields) even if defaults=false
          objects: true,  // populates empty objects (map fields) even if defaults=false
          oneofs: true    // includes virtual oneof fields set to the present field's name
        });

        if (pb_message.error.code === PBErrorCode.Success) {
          resolve(pbMessageObject)
        } else {
          reject(pbMessageObject)
        }
      })
    })
  })
}

const buildMessage = (data, apiType, accessToken) => {
  return new Promise((resolve, reject) => {
     Protobuf.load(proto_file_url, (err, root) => {
      if (err) {
        reject(err)
        return
      }

      const { PBErrorCode, PBError, PBMessage, PBAPIType } = root;

      let error = {
        code: PBErrorCode.Success,
        msgs: ''
      }

      let created_request_message = root[`PBAPI${apiType}Request`].fromObject(data)

      let created_message = PBMessage.create({
        uuid: Utils.uuidv4(),
        accessToken,
        apiType: PBAPIType[apiType],
        error: PBError.fromObject(error)
      })

      let request_specified_key = `${Utils.camelize(apiType)}Request`
      created_message[request_specified_key] = created_request_message
      created_message['request'] = request_specified_key

      let buffer = PBMessage.encode(created_message).finish()

      resolve(new Blob([buffer]))
    })
  })
}

export default {
  buildMessage,
  getResponseObject
}
