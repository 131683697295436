import { useEffect } from 'react'
import useOrientationChange from "use-orientation-change"

let throttle = false
export default (onResize) => {
  const orientation = useOrientationChange()

  useEffect(() => {
    let resizeEndAction
    const onWindowResize = () => {
      if (!throttle) {
        onResize()
        throttle = true

        setTimeout(() => {
          throttle = false
        }, 100)
      }
      clearTimeout(resizeEndAction)
      resizeEndAction = setTimeout(onResize, 100)
    }

    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [orientation, onResize])

  return null
}
