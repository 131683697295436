import React from 'react';
import './index.css';
import './resp.css';
import Modal from 'react-modal';
import IgbButton from '../IgbButton';
import IgbIconButtonWrapper from '../IgbIconButtonWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      padding: 0
    },
    overlay: {
      zIndex: 10,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  };


  Modal.setAppElement('#root')

  return (
    <Modal
      isOpen={props.isModalOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      >
      <div className='inner-container'>
        {
          props.hideCloseButton ? null :
          <IgbIconButtonWrapper className='close-icon' onClick={props.closeModal}>
            <FontAwesomeIcon color='#617288' icon={'times'} />
          </IgbIconButtonWrapper>
        }
        { props.children }
        <IgbButton className='igb-is-primary action-button' onClick={props.onClick}>{props.buttonText}</IgbButton>
      </div>
    </Modal>
  )
}
