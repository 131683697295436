import React from 'react';
import './index.css';
import InsBusinesssTag from '../../../assets/images/ins_business_tag.svg';

export default (props) => {
  return (
    <div className={`igb-avatar-wrapper ${props.size} ${props.className || ''}`}>
      { props.children }
      { props.withInsTag ? <img className='ins-tag' src={InsBusinesssTag} alt='' /> : null}
    </div>
  )
}
