import React from 'react';
import './App.css';
import 'bulma/css/bulma.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from './components/auth/PrivateRoute';
import Locales, { LocaleContext } from './services/Locales';
import Overview from './pages/Overview';
import Engagement from './pages/Engagement';
import Settings from './pages/Settings';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Stories from './pages/Stories';
import StartPage from './pages/StartPage';
import ResetPassword from './pages/ResetPassword';
import ConnectFb from './pages/ConnectFb';
import TermsOfUse from './pages/TermsOfUse';
import AccountActivation from './pages/AccountActivation';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas)

const NotFound = () => {
  return (
    <div>
      <h1>Notfound page</h1>
    </div>
  )
}

function App() {
  return (
    <LocaleContext.Provider value={Locales['en']}>
      <Router>
        <div>
          <Switch>
            <Route exact path='/' component={StartPage} />
            <Route path='/sign_up' component={SignUp} />
            <Route path='/login' component={SignIn} />
            <Route path='/account_activation/:code' component={AccountActivation} />
            <Route exact path='/reset_password' component={ResetPassword} />
            <Route path='/reset_password/:code' component={ResetPassword} />
            <Route path='/connect_fb' component={ConnectFb} />
            <Route path='/terms_of_use' component={TermsOfUse} />
            <PrivateRoute path='/engagement' component={Engagement} />
            <PrivateRoute path='/settings' component={Settings} />
            <PrivateRoute path='/overview' component={Overview} />
            <PrivateRoute path='/stories' component={Stories} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </LocaleContext.Provider>
  );
}

export default App;
