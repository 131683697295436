import React from 'react'
import './index.css'
import './resp.css'
import IgbIconButtonWrapper from '../IgbIconButtonWrapper'
import CloseIcon from '../../../assets/images/close-icon.png'
import CloseIconInfo from '../../../assets/images/icon_close_gray.png'

export default (props) => {
  return (
    <div className={`form-message ${props.type ? `fm-${props.type}` : ''} ${props.className || ''}`}>
      <IgbIconButtonWrapper className='close' onClick={props.closeMessage}>
        <img src={props.type === 'error' ? CloseIcon : CloseIconInfo} width='12' height='12' alt='' />
      </IgbIconButtonWrapper>
      {
        props.type === 'error' && !props.hideErrorPrefix ? <span className='error-head'>Error:</span> : null
      }
      {props.children}
    </div>
  )
}
