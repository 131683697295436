import React, { useState, useEffect } from 'react'
import './index.css'
import './resp.css'
import Modal from 'react-modal'
import IgbButton from '../IgbButton'
import IgbIconButtonWrapper from '../IgbIconButtonWrapper'
import BgWave from '../../../assets/images/bg-wave.svg'
import BgWaveSm from '../../../assets/images/bg-wave-sm.png'
import BoomStar from '../../../assets/images/boom-star.svg'
import PeopleCheering from '../../../assets/images/people-cheering.png'
import CloseIconWhite from '../../../assets/images/icon_close_white.png'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

export default (props) => {
  const [isProcessingData, setIsProcessingData] = useState(false)

  const local = useSelector(state => state.local)
  const user = useSelector(state => state.user)
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' })

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '20px',
      padding: 0,
      border: 'none'
    },
    overlay: {
      zIndex: 10,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  }

  useEffect(() => {
    let selectedPage = local.selectedPage || user.account.fbPages[0]

    setIsProcessingData(selectedPage && selectedPage.connectState === "PBFbPageConnectStateWaiting")
  }, [local, user])

  Modal.setAppElement('#root')

  return (
    <Modal
      isOpen={props.isModalOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >

      <div className='waiting-modal inner-container'>
        <img className='bg-wave' src={isMobileDevice ? BgWaveSm : BgWave} alt='purple background' />
        <img className='boom-star left larger' src={BoomStar} alt='star' />
        <img className='boom-star right smaller top' src={BoomStar} alt='star' />
        <img className='boom-star right smaller bottom' src={BoomStar} alt='star' />
        <div className='position-hold-wrapper'>
          <img src={PeopleCheering} className='people-cheering' alt='people cheering' />
        </div>
        {
          isProcessingData ? null :
            <IgbIconButtonWrapper className='close-icon' onClick={props.closeModal}>
              <img src={CloseIconWhite} alt='cross' />
            </IgbIconButtonWrapper>
        }
        <div>
          <div className='modal-title'>{isProcessingData ? 'Welcome to Ig Analyzer Business!' : 'Enjoy Ig Analyzer Business!'}</div>
          <div className='modal-description'>
            {
              isProcessingData ?
                `The first update may take approx 30 minutes as we fetch your Instagram data. Please take a break and check the data we have sorted out for you later.` :
                `Everything is ready! Please enjoy Ig Analyzer Business!`
            }
          </div>
        </div>
        {isProcessingData ? null : <IgbButton className='igb-is-primary full-size' onClick={props.closeModal}>OK</IgbButton>}
      </div>
    </Modal>
  )
}
