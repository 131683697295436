import React, { useState, useEffect } from 'react'
import './index.css'
import './resp.css'
import StartingFrame from '../../components/ui/StartingFrame'
import IgbFormField from '../../components/ui/IgbFormField'
import IgbButton from '../../components/ui/IgbButton'
import LogoBlue from '../../assets/images/logo_blue.svg'
import { Link, Redirect } from "react-router-dom"
import { Formik } from "formik"
import * as yup from "yup"
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import FormMessage from '../../components/ui/FormMessage'
import Constants from '../../services/Constants'
import ClipLoader from "react-spinners/ClipLoader"

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || `${ref.path} must be the same as ${ref}`,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

yup.addMethod(yup.string, 'equalTo', equalTo)

export default () => {
  const [errorMessage, setErrorMessage] = useState()

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.signUpError) {
      setErrorMessage(user.signUpError.msgs)
    }
  }, [user])

  const handleSignUpSubmit = values => {
    dispatch({ type: 'SEND_REQUEST', data: values, apiType: Constants.apiTypes.signUp })
  }

  const contentForm = (
    <div className='form-sign-up-container igb-sign-up-form-container'>
      <div className='sign-up-title'>
        <Link to={'/'}><img src={LogoBlue} alt='' /></Link>
        <div className='sign-up-to'>Sign up to</div>
        <div>Ig Analyzer Business</div>
      </div>
      {errorMessage ? <FormMessage type='error' closeMessage={() => { setErrorMessage(null) }}>{errorMessage}</FormMessage> : null}
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordConfirm: ''
        }}
        onSubmit={handleSignUpSubmit}
        validationSchema={yup.object().shape({
          email: yup.string()
            .email("Must be valid email")
            .required("Required"),
          password: yup.string()
            .required("No password provided.")
            .min(8, "Password should be 8 chars minimum.")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^~`()-_=+,./;'])[A-Za-z\d@$!%*?&#^~`()-_=+,./;']{8,}$/, "Must contain number and uppercase&lowercase letter"),
          passwordConfirm: yup.string().equalTo(yup.ref('password'), 'Passwords must match').required('Required')
        })}
      >
        {
          props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props
            return (
              <form onSubmit={handleSubmit}>
                <IgbFormField
                  inputClassName={errors.email && touched.email ? "error" : ""}
                  showError={errors.email && touched.email}
                  error={errors.email}
                  isRequired
                  label='Email Address'
                  inputName={'email'}
                  value={values.email}
                  helpInfo={`Please enter a valid email address as we‘ll send you an account confirmation link by email`}
                  className='signup-tooltip'
                  onChange={handleChange}
                  onBlur={handleBlur} />
                <IgbFormField
                  inputClassName={errors.password && touched.password ? "error" : ""}
                  showError={errors.password && touched.password}
                  error={errors.password}
                  isRequired
                  inputType='password'
                  label='Password'
                  inputName={'password'}
                  value={values.password}
                  helpInfo={`Your password must contain at least 8 characters, it must also include at least one upper case and one number`}
                  className='signup-tooltip'
                  onChange={handleChange}
                  onBlur={handleBlur} />
                <IgbFormField
                  inputClassName={errors.passwordConfirm && touched.passwordConfirm ? "error" : ""}
                  showError={errors.passwordConfirm && touched.passwordConfirm}
                  error={errors.passwordConfirm}
                  isRequired
                  inputType='password'
                  label='Confirm Password'
                  inputName={'passwordConfirm'}
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                <div className='actions'>
                  <IgbButton className='igb-is-primary start-button' disabled={JSON.stringify(errors) !== '{}' || user.signingUp || !(values.email && values.password && values.passwordConfirm)} buttonType={'submit'}>{user.signingUp ? <ClipLoader
                    size={30}
                    color={"#FFF"}
                    loading={true}
                  /> : `Let's start!`}</IgbButton>
                </div>
              </form>
            )
          }
        }
      </Formik>
      <div className='extra-info'>
        By signing up, you agree to our <Link className={`link`} to='/terms_of_use'> Terms of use.</Link><br />Already have an account? <Link className={`link`} to='/login'>Log in</Link>
      </div>
    </div>
  )

  if (user.loggedIn) {
    return <Redirect to={'/overview'} />
  } else {
    return (
      <div>
        <Helmet>
          <title>Ig Analyzer Business, Sign up</title>
        </Helmet>
        <StartingFrame className='starting-frame'>
          <div className='content-wrapper'>
            {contentForm}
          </div>
        </StartingFrame>
        <div className='mobile-starting-frame'>
          {contentForm}
        </div>
      </div>
    )
  }
}
