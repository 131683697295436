import React from 'react';
import './index.css';
import './resp.css';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Constants from '../../services/Constants';
import LightPageWrapper from '../../components/ui/LightPageWrapper';
import IgbButton from '../../components/ui/IgbButton';
import InsIcon from '../../assets/images/ins-icon.png';
import InsSuccessBadge from '../../assets/images/success-badge.png';
import InsErrorBadge from '../../assets/images/error-badge.png';

export default () => {
  const [isLoginFailed, setIsLoginFailed] = React.useState(false)
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const connectToFb = () => {
    window.FB.login(function(response) {
      if (response.authResponse) {
        setIsLoginFailed(false)
        dispatch({type: 'SEND_REQUEST', data: {fbPageAccessToken: response.authResponse.accessToken}, apiType: Constants.apiTypes.connectFbPage})
      } else {
        setIsLoginFailed(true)
      }
    }, {
      scope: 'pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,email',
      auth_type: 'reauthenticate'
    });
  }

  if (!user.loggedIn) {
    return (
      <Redirect to={{
          pathname: '/login',
          state: { from: '/connect_fb' }
        }} />
    )
  }

  if (user.fbConnected) {
    return (
      <Redirect to={{
          pathname: '/overview',
          state: { from: '/connect_fb' }
        }} />
    )
  }

  const handleLogout = () => {
    dispatch({type: 'SEND_REQUEST', data: {}, apiType: Constants.apiTypes.logout})
  }

  return (
    <LightPageWrapper className='connect-fb-page'>
      <div className='connect-wrapper'>
        <div className='connect-container'>
          <div className='avatar-wrapper'>
            <img src={InsIcon} width='140' alt='ins logo' />
            <img className='badge' src={isLoginFailed ? InsErrorBadge : InsSuccessBadge} alt='badge' />
          </div>
          <div className='connect-content'>
            <div className='connect-title'>{isLoginFailed ? 'Something went wrong' : 'Let’s connect to your Instagram'}</div>
            <div className='connect-description'>
              { isLoginFailed ? `Looks like something went wrong when your instagram was connected, please try again.` : `You will be redirected to Facebook in the following step. Select the Instagram Business profiles you'd like to connect!` }
            </div>
          </div>
          <IgbButton className='igb-is-primary connect-fb' onClick={connectToFb}>Connect to your Instagram account</IgbButton>
          <Link className={`sign-in cb-logout`} to='/login' onClick={handleLogout}>Log out</Link>
        </div>
      </div>
    </LightPageWrapper>
  )
}
