import React from 'react';
import './index.css';
import './resp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {
  const statusIconMap = {
    'CONNECTED': {
      iconName: 'check-circle',
      color: '#36C736'
    },
    'NOT_CONNECTED': {
      iconName: 'times-circle',
      color: '#FF4E66'
    },
    'INACTIVE': {
      iconName: 'info-circle',
      color: '#637279'
    }
  }

  return (
    <div className='profile-status'>
      <div className='status-icon'>
        <FontAwesomeIcon color={statusIconMap[props.status.key]['color']} icon={statusIconMap[props.status.key]['iconName']} />
      </div>
      <div className='info'>
        <div className='status-value' style={{color: statusIconMap[props.status.key]['color']}}>{props.status.value}</div>
        <div className='profile-type'>{props.type}</div>
      </div>
    </div>
  )
}
