import React, { useState, useEffect, useContext, useRef } from 'react'
import './index.css'
import './resp.css'
import MainFrame from '../../components/ui/MainFrame'
import ThinSummaryCard from '../../components/ui/ThinSummaryCard'
import PageContentTitleLine from '../../components/ui/PageContentTitleLine'
import { LocaleContext } from '../../services/Locales'
import LineChart from '../../components/ui/LineChart'
import MediaItem from '../../components/ui/MediaItem'
import IgbDropdown from '../../components/ui/IgbDropdown'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import Constants from '../../services/Constants'
import Utils from '../../services/Utils'
import { setDateRange } from '../../_actions/local.actions'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { useMediaQuery } from 'react-responsive'
import useWindowResizeHandler from '../../components/hooks/useWindowResizeHandler'
import EngagementTotalLikes from '../../assets/images/engagement_total_likes.svg'
import EngagementAvgLikes from '../../assets/images/engagement_avg_likes.svg'
import EngagementCommentsTotal from '../../assets/images/engagement_comments_total.svg'
import EngagementAvgCommentsPerPost from '../../assets/images/engagement_avg_comments_per_post.svg'
import DefaultCamera from '../../assets/images/default_camera.svg'

const { engaging, recent, liked, commented } = Constants.mediaMostActionTypes
const { none, image, video } = Constants.mediaTypes

const mostActions = [
  { label: 'Most Engaging Media', type: engaging },
  { label: 'Most Recent Media', type: recent },
  { label: 'Most Liked Media', type: liked },
  { label: 'Most Commented Media', type: commented }
]

const mediaTypes = [
  { label: 'All', type: none },
  { label: 'Photo', type: image },
  { label: 'Video', type: video }
]

export default (props) => {
  const [mediaItemDimenssion, setMediaItemDimenssion] = useState({})
  const [selectedMostAction, setSelectedMostAction] = useState(mostActions[0])
  const [selectedMediaType, setSelectedMediaType] = useState(mediaTypes[0])

  const firstMediaRef = useRef()
  const user = useSelector(state => state.user)
  const local = useSelector(state => state.local)
  const analysis = useSelector(state => state.analysis)
  const dispatch = useDispatch()
  const locale = useContext(LocaleContext)
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' })
  const isSmallerDevice = useMediaQuery({ query: '(max-width: 666px)' })
  const isMiddleSmallDevice = useMediaQuery({ query: '(max-width: 1023px)' })
  const isSpecialFix = useMediaQuery({ query: '(max-width: 1080px)' })
  const isMiddleDevice = useMediaQuery({ query: '(max-width: 1323px)' })
  const isMiddleLargeDevice = useMediaQuery({ query: '(max-width: 1616px)' })
  const isLargerDevice = useMediaQuery({ query: '(max-width: 1919px)' })

  useEffect(() => {
    const baseParams = Utils.buildBaseParams(local, user)
    if (baseParams) {
      dispatch({ type: 'SEND_REQUEST', data: baseParams, apiType: Constants.apiTypes.fetchIgbMediaMetric })
      dispatch({ type: 'SEND_REQUEST', data: baseParams, apiType: Constants.apiTypes.fetchIgbMediaLikesHistory })
      dispatch({ type: 'SEND_REQUEST', data: baseParams, apiType: Constants.apiTypes.fetchIgbMediaCommentsHistory })
    }
  }, [local, user, dispatch])

  useEffect(() => {
    const baseParams = Utils.buildBaseParams(local, user)
    if (baseParams) {
      let mostActionsParams = { ...baseParams, actionType: selectedMostAction.type, mediaType: selectedMediaType.type }
      dispatch({ type: 'SEND_REQUEST', data: mostActionsParams, apiType: Constants.apiTypes.fetchIgbMediaMostActionsList })
    }
  }, [local, user, dispatch, selectedMediaType, selectedMostAction])

  const resetDimenssions = () => {
    if (!firstMediaRef.current) {
      return
    }
    let dimensions = firstMediaRef.current.getBoundingClientRect()
    setMediaItemDimenssion(dimensions)
  }

  useEffect(resetDimenssions, [analysis.mediaMostActionsList, firstMediaRef.current])

  useWindowResizeHandler(resetDimenssions)

  const buildSlides = () => {
    let selectedPage = local.selectedPage || user.account.fbPages[0]

    const igbUserAvatar = selectedPage.igbUser.profilePictureUrl
    const { medias } = analysis.mediaMostActionsList

    return medias.map((m, i) => {
      let media = {
        igbUserAvatar,
        mediaUrl: m.mediaUrl,
        likeCount: m.likeCount,
        mediaType: m.mediaType,
        durationText: Utils.getDurationText(m.timestamp),
        commentsCount: m.commentsCount
      }
      return <Slide key={i} index={i}>{i === 0 ? <MediaItem ref={firstMediaRef} media={media} /> : <MediaItem media={media} />}</Slide>
    })
  }

  const getVisibleSlidesNumber = () => {
    if (isMobileDevice) { return 1 }
    if (isSmallerDevice) { return 2 }
    if (isMiddleSmallDevice) { return 3 }
    if (isSpecialFix) { return 2 }
    if (isMiddleDevice) { return 3 }
    if (isMiddleLargeDevice) { return 4 }
    if (isLargerDevice) { return 5 }
    return 6
  }

  const updateDateRange = (startDate, endDate) => {
    dispatch(setDateRange({
      sDate: startDate,
      eDate: endDate
    }))
  }

  const { comments, commentsPerPost, likes, likesPerPost } = analysis.userMediaMetric || {}

  return (
    <MainFrame className='engagement'>
      <PageContentTitleLine startDate={local.dateRange.sDate} endDate={local.dateRange.eDate} updateDates={updateDateRange} contentTitle={locale['pages']['engagement']['title']} />
      <div className='summaries'>
        <ThinSummaryCard metric={'userMediaMetric'} catIcon={EngagementTotalLikes} data={Utils.buildCardData(likes, 'Total likes')}
          helpText={`Total number of likes received for all posts PUBLISHED within the selected dates, regardless of the date on which these likes were received. Includes paid and organic activity.`} />
        <ThinSummaryCard metric={'userMediaMetric'} catIcon={EngagementAvgLikes} data={Utils.buildCardData(likesPerPost, 'Average likes per post')} />
        <ThinSummaryCard metric={'userMediaMetric'} catIcon={EngagementCommentsTotal} data={Utils.buildCardData(comments, 'Comments total')}
          helpText={`Total number of comments received for all posts PUBLISHED within the selected dates, regardless of the date on which these comments were received. Includes paid and organic activity.`} />
        <ThinSummaryCard metric={'userMediaMetric'} catIcon={EngagementAvgCommentsPerPost} data={Utils.buildCardData(commentsPerPost, 'Average comments per post')} />
      </div>
      <div className='charts'>
        <LineChart
          rotateTicksX
          className='history likes'
          title={'Likes History'}
          divideLine
          alignment={'left'}
          mainInstruction={{ text: 'Likes', width: 31 }}
          secondaryInstruction={{ text: 'Average Likes per day', width: 133 }}
          firstLabel={'Likes'}
          secondLabel={'Average'}
          margin={{ top: 145, right: 50, bottom: 60, left: 60 }}
          averageValue={analysis.mediaLikesHistory ? analysis.mediaLikesHistory.average : 0}
          mainData={analysis.mediaLikesHistory ? analysis.mediaLikesHistory.likesHistoryItems : []}
          secondaryData={analysis.mediaLikesHistory ? analysis.mediaLikesHistory.likesHistoryItems.map((item) => {
            return {
              value: analysis.mediaLikesHistory.average,
              date: item.date
            }
          }) : []}
          mainLine={{ strokeColor: '#FF4E66' }}
          point={{ strokeColor: '#FFF', fillColor: '#FF4E66' }}
          secondaryLine={{ strokeColor: '#D6DDE1' }} />
        <LineChart
          rotateTicksX
          className='history comments'
          title={'Comments History'}
          divideLine
          alignment={'left'}
          mainInstruction={{ text: 'Comments', width: 64 }}
          secondaryInstruction={{ text: 'Average Comments per day', width: 166 }}
          firstLabel={'Comments'}
          secondLabel={'Average'}
          margin={{ top: 145, right: 50, bottom: 60, left: 60 }}
          averageValue={analysis.mediaCommentHistory ? analysis.mediaCommentHistory.average : 0}
          mainData={analysis.mediaCommentHistory ? analysis.mediaCommentHistory.commentsHistoryItems : []}
          secondaryData={analysis.mediaCommentHistory ? analysis.mediaCommentHistory.commentsHistoryItems.map((item) => {
            return {
              value: analysis.mediaCommentHistory.average,
              date: item.date
            }
          }) : []}
          mainLine={{ strokeColor: '#0184FF' }}
          point={{ strokeColor: '#FFF', fillColor: '#0184FF' }}
          secondaryLine={{ strokeColor: '#D6DDE1' }} />
      </div>
      <div className='medias'>
        <div className='selections'>
          <IgbDropdown selections={mostActions} updateSelect={setSelectedMostAction} selected={selectedMostAction} buttonClassname='actions-dropdown-label' />
          <IgbDropdown selections={mediaTypes} updateSelect={setSelectedMediaType} selected={mediaTypes[0]} buttonClassname='mediatypes-dropdown-button' />
        </div>
        <div className='carousel-container'>
          {
            analysis.mediaMostActionsList && analysis.mediaMostActionsList.medias.length > 0 ?
              <CarouselProvider
                naturalSlideWidth={mediaItemDimenssion.width}
                naturalSlideHeight={mediaItemDimenssion.height}
                totalSlides={analysis.mediaMostActionsList.medias.length}
                visibleSlides={getVisibleSlidesNumber()}
                className='carousel-provider'
              >
                <Slider>
                  {buildSlides()}
                </Slider>
                <ButtonBack className='slide-back'><FontAwesomeIcon className='icon' color='#7F89A1' size={'3x'} icon={'chevron-left'} /></ButtonBack>
                <div className='back-button-bg'></div>
                <ButtonNext className='slide-next'><FontAwesomeIcon className='icon' color='#7F89A1' size={'3x'} icon={'chevron-right'} /></ButtonNext>
                <div className='next-button-bg'></div>
              </CarouselProvider> :
              <div className='default-info'>
                <img src={DefaultCamera} className='camera' alt='camera' />
                <div className='comments'>No media published in this period. (<Link to='/learn-more'>Learn more</Link>)</div>
              </div>
          }
        </div>
      </div>
    </MainFrame>
  )
}
