import { useState, useEffect } from 'react'

export default () => {
  const [activeLinkPath, setActiveLinkPath] = useState('')

  useEffect(() => {
    let pathName = window.location.pathname.split('/')[1]
    setActiveLinkPath(pathName.toUpperCase())
  }, [])

  return activeLinkPath
}
