import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './index.css'
import './resp.css'
import StartingFrame from '../../components/ui/StartingFrame'
import IgbFormField from '../../components/ui/IgbFormField'
import IgbButton from '../../components/ui/IgbButton'
import FormMessage from '../../components/ui/FormMessage'
import Constants from '../../services/Constants'
import { setIsLoginRequired } from '../../_actions'

import LogoBlue from '../../assets/images/logo_blue.svg'
import { Link, Redirect } from "react-router-dom"
import { Formik } from "formik"
import * as yup from "yup"
import { Helmet } from 'react-helmet-async'
import ClipLoader from "react-spinners/ClipLoader"

export default (props) => {
  const [errorMessage, setErrorMessage] = useState()

  const user = useSelector(state => state.user)
  const globalError = useSelector(state => state.globalError)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.signInError) {
      setErrorMessage(user.signInError.msgs)
    }
  }, [user])

  const handleSignInSubmit = values => {
    setErrorMessage(null)
    dispatch(setIsLoginRequired(false))
    dispatch({ type: 'SEND_REQUEST', data: values, apiType: Constants.apiTypes.login })
  }

  const signInForm = (
    <div className='form-sign-in-container igb-sign-in-form-container'>
      <div className='sign-in-title'>
        <Link to={'/'}><img src={LogoBlue} alt='' /></Link>
        <div className='sign-in-to'>Log in to</div>
        <div>Ig Analyzer Business</div>
      </div>
      {errorMessage ? <FormMessage type='error' closeMessage={() => { setErrorMessage(null) }}>{errorMessage}</FormMessage> : null}
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={handleSignInSubmit}
        validationSchema={yup.object().shape({
          email: yup.string()
            .email("Must be valid email")
            .required("Required"),
          password: yup.string()
            .required("No password provided.")
        })}
      >
        {
          props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props
            return (
              <form onSubmit={handleSubmit}>
                <IgbFormField
                  inputClassName={errors.email && touched.email ? "error" : ""}
                  showError={errors.email && touched.email}
                  error={errors.email}
                  isRequired
                  label='Email Address'
                  inputName={'email'}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                <IgbFormField
                  inputClassName={errors.password && touched.password ? "error" : ""}
                  showError={errors.password && touched.password}
                  error={errors.password}
                  isRequired
                  inputType='password'
                  label='Password'
                  inputName={'password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                <div className='forget-password'><Link className='reset-password' to='/reset_password'>Forgot your password?</Link></div>
                <div className='actions'>
                  <IgbButton className='igb-is-primary start-button' disabled={JSON.stringify(errors) !== '{}' || user.loggingIn || !(values.email && values.password)} buttonType={'submit'}>
                    {user.loggingIn ?
                      <ClipLoader
                        size={30}
                        color={"#FFF"}
                        loading={true}
                      /> : `Let's start!`}
                  </IgbButton>
                </div>
              </form>
            )
          }
        }
      </Formik>
      <div className='extra-info'>
        Not registered yet? <Link className={`to-sign-up`} to='/sign_up'>Create a new account</Link>
      </div>
    </div>
  )

  const { from } = props.location.state || { from: { pathname: '/overview' } }

  if (user.loggedIn && !globalError.isLoginRequired) {
    return <Redirect to={from} />
  } else {
    return (
      <div>
        <Helmet>
          <title>Ig Analyzer Business, Log in</title>
        </Helmet>
        <StartingFrame className='sign-in-starting-frame'>
          <div className='content-wrapper'>
            {signInForm}
          </div>
        </StartingFrame>
        <div className='sign-in-mobile-frame'>
          {signInForm}
        </div>
      </div>
    )
  }
}
