import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import websocketMiddleware from '../_middleware/websocketMiddleware';
import rootReducer from '../_reducers';

const loggerMiddleware = createLogger();
const engine = createEngine('igb-save');
const persistMiddleware = storage.createMiddleware(engine);

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        websocketMiddleware,
        loggerMiddleware,
        persistMiddleware
    )
);

const load = storage.createLoader(engine);

load(store)
    .then((newState) => console.log('Loaded state:', newState))
    .catch(() => console.log('Failed to load previous state'));
