import { analysisConstants } from '../_action_constants';

const toMillisecondsDate = (item) => {
  item.date *= 1000
  return item
}

export default function analysis(state = {}, action) {
  switch (action.type) {
    case analysisConstants.USER_METRIC_REQUEST:
      return {
        ...state,
        userMetricRequesting: true
      }
    case analysisConstants.USER_METRIC_SUCCESS:
      return {
        ...state,
        userMetricRequesting: false,
        userMetric: action.data,
        userMetricError: null
      }
    case analysisConstants.USER_METRIC_FAILURE:
      return {
        ...state,
        userMetricRequesting: false,
        userMetricError: action.error
      }
    case analysisConstants.USER_FOLLOWERS_HISTORY_REQUEST:
      return {
        ...state,
        userFollowerHistoryRequesting: true
      }
    case analysisConstants.USER_FOLLOWERS_HISTORY_SUCCESS:
      if (action.data) {
        action.data.followersHistoryItems = action.data.followersHistoryItems.map(toMillisecondsDate)
      }
      return {
        ...state,
        userFollowerHistoryRequesting: false,
        userFollowerHistory: action.data,
        userFollowerHistoryError: null
      }
    case analysisConstants.USER_FOLLOWERS_HISTORY_FAILURE:
      return {
        ...state,
        userFollowerHistoryRequesting: false,
        userFollowerHistoryError: action.error
      }
    case analysisConstants.MEDIA_METRIC_REQUEST:
      return {
        ...state,
        userMediaMetricRequesting: true,
      }
    case analysisConstants.MEDIA_METRIC_SUCCESS:
      return {
        ...state,
        userMediaMetricRequesting: false,
        userMediaMetric: action.data,
        userMediaMetricError: null
      }
    case analysisConstants.MEDIA_METRIC_FAILURE:
      return {
        ...state,
        userMediaMetricRequesting: false,
        userMediaMetricError: action.error
      }
    case analysisConstants.MEDIA_LIKES_HISTORY_REQUEST:
      return {
        ...state,
        mediaLikesHistoryRequesting: true,
      }
    case analysisConstants.MEDIA_LIKES_HISTORY_SUCCESS:
      if (action.data) {
        action.data.likesHistoryItems = action.data.likesHistoryItems.map(toMillisecondsDate)
      }
      return {
        ...state,
        mediaLikesHistoryRequesting: false,
        mediaLikesHistory: action.data,
        mediaLikesHistoryError: null
      }
    case analysisConstants.MEDIA_LIKES_HISTORY_FAILURE:
      return {
        ...state,
        mediaLikesHistoryRequesting: false,
        mediaLikesHistoryError: action.error
      }
    case analysisConstants.MEDIA_COMMENTS_HISTORY_REQUEST:
      return {
        ...state,
        mediaCommentHistoryRequesting: true
      }
    case analysisConstants.MEDIA_COMMENTS_HISTORY_SUCCESS:
      if (action.data) {
        action.data.commentsHistoryItems = action.data.commentsHistoryItems.map(toMillisecondsDate)
      }
      return {
        ...state,
        mediaCommentHistoryRequesting: false,
        mediaCommentHistory: action.data,
        mediaCommentHistoryError: null
      }
    case analysisConstants.MEDIA_COMMENTS_HISTORY_FAILURE:
      return {
        ...state,
        mediaCommentHistoryRequesting: false,
        mediaCommentHistoryError: action.error
      }
    case analysisConstants.MEDIA_MOST_ACTIONS_LIST_REQUEST:
      return {
        ...state,
        mediaMostActionsListRequesting: true
      }
    case analysisConstants.MEDIA_MOST_ACTIONS_LIST_SUCCESS:
      return {
        ...state,
        mediaMostActionsListRequesting: false,
        mediaMostActionsList: action.data,
        mediaMostActionsListError: null
      }
    case analysisConstants.MEDIA_MOST_ACTIONS_LIST_FAILURE:
      return {
        ...state,
        mediaMostActionsListRequesting: false,
        mediaMostActionsListError: action.error
      }
    case analysisConstants.STORY_METRIC_REQUEST:
      return {
        ...state,
        storyMetricRequesting: true
      }
    case analysisConstants.STORY_METRIC_SUCCESS:
      return {
        ...state,
        storyMetricRequesting: false,
        storyMetric: action.data,
        storyMetricError: null
      }
    case analysisConstants.STORY_METRIC_FAILURE:
      return {
        ...state,
        storyMetricRequesting: false,
        storyMetricError: action.error
      }
    case analysisConstants.STORY_REACH_HISTORY_REQUEST:
      return {
        ...state,
        storyReachHistoryRequesting: true
      }
    case analysisConstants.STORY_REACH_HISTORY_SUCCESS:
      if (action.data) {
        action.data.reachHistoryItems = action.data.reachHistoryItems.map(toMillisecondsDate)
      }
      return {
        ...state,
        storyReachHistoryRequesting: false,
        storyReachHistory: action.data,
        storyReachHistoryError: null
      }
    case analysisConstants.STORY_REACH_HISTORY_FAILURE:
      return {
        ...state,
        storyReachHistoryRequesting: false,
        storyReachHistoryError: action.error
      }
    case analysisConstants.STORY_IMPRESSION_HISTORY_REQUEST:
      return {
        ...state,
        storyImpressionHistoryRequesting: true
      }
    case analysisConstants.STORY_IMPRESSION_HISTORY_SUCCESS:
      if (action.data) {
        action.data.impressionHistoryItems = action.data.impressionHistoryItems.map(toMillisecondsDate)
      }
      return {
        ...state,
        storyImpressionHistoryRequesting: false,
        storyImpressionHistory: action.data,
        storyImpressionHistoryError: null
      }
    case analysisConstants.STORY_IMPRESSION_HISTORY_FAILURE:
      return {
        ...state,
        storyImpressionHistoryRequesting: false,
        storyImpressionHistoryError: action.error
      }
    default:
      return state
  }
}
