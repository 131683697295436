import React, { useEffect, useContext } from 'react'
import './index.css'
import './resp.css'
import MainFrame from '../../components/ui/MainFrame'
import FatSummaryCard from '../../components/ui/FatSummaryCard'
import PageContentTitleLine from '../../components/ui/PageContentTitleLine'
import { LocaleContext } from '../../services/Locales'

import { Helmet } from 'react-helmet-async'
import Utils from '../../services/Utils'
import { useDispatch, useSelector } from 'react-redux'
import Constants from '../../services/Constants'
import { setDateRange } from '../../_actions/local.actions'

import LineChart from '../../components/ui/LineChart'
import OverviewTotalFollowers from '../../assets/images/overview_total_followers.svg'
import OverviewTotalFollowing from '../../assets/images/overview_total_following.svg'
import OverviewImpressions from '../../assets/images/overview_impressions.svg'
import OverviewReach from '../../assets/images/overview_reach.svg'
import OverviewAvgReachPerPost from '../../assets/images/overview_avg_reach_per_post.svg'
import OverviewAvgReachRateByPost from '../../assets/images/overview_avg_reach_rate_by_post.svg'

export default () => {
  const locale = useContext(LocaleContext)
  const user = useSelector(state => state.user)
  const analysis = useSelector(state => state.analysis)
  const local = useSelector(state => state.local)
  const dispatch = useDispatch()

  useEffect(() => {
    let { fbPages } = user.account
    if (fbPages && fbPages.length > 0) {
      let selectedPage = local.selectedPage || fbPages[0]
      let params = {
        igbUserId: selectedPage.igbUser.igbId,
        startDate: local.dateRange.sDate.unix(),
        endDate: local.dateRange.eDate.unix()
      }

      dispatch({ type: 'SEND_REQUEST', data: params, apiType: Constants.apiTypes.fetchIgbUserMetric })
      dispatch({ type: 'SEND_REQUEST', data: params, apiType: Constants.apiTypes.fetchIgbUserFollowersHistory })
    }
  }, [local, dispatch, user.account])

  const updateDateRange = (startDate, endDate) => {
    dispatch(setDateRange({
      sDate: startDate || local.dateRange.sDate,
      eDate: endDate || local.dateRange.eDate
    }))
  }

  const { followers, follows, impression, reach, reachPerPost, reachByPost } = analysis.userMetric || {}

  return (
    <MainFrame className='overview'>
      <Helmet>
        <title>Ig Analyzer Business</title>
      </Helmet>
      <PageContentTitleLine startDate={local.dateRange.sDate} endDate={local.dateRange.eDate} updateDates={updateDateRange} contentTitle={locale['pages']['overview']['title']} />
      <div className='summaries'>
        <FatSummaryCard className='card' catIcon={OverviewTotalFollowers} data={Utils.buildCardData(followers, 'Total followers')} noTrending />
        <FatSummaryCard className='card' catIcon={OverviewTotalFollowing} data={Utils.buildCardData(follows, 'Total following')} noTrending />
        <FatSummaryCard className='card' catIcon={OverviewImpressions} data={Utils.buildCardData(impression, 'Impression')}
          helpText={`The total number of times your media objects (i.e. posts, stories and promotions) have been showed. Includes ad activity generated through the API, Facebook ads interfaces, and the Promote feature. Does not include profile views. `} />
        <FatSummaryCard className='card' catIcon={OverviewReach} data={Utils.buildCardData(reach, 'Reach')}
          helpText={`the total number of unique users have been obtained by your objects (i.e. posts, stories and promotions) over the chosen period. Includes ad activity generated through the API, Facebook ads interfaces, and the Promote feature. `} />
        <FatSummaryCard className='card' catIcon={OverviewAvgReachPerPost} data={Utils.buildCardData(reachPerPost, 'Average reach per post')}
          helpText={`The average number of unique people who have seen one of your posts within the selected dates. Includes paid and organic activity.`} />
        <FatSummaryCard className='card' catIcon={OverviewAvgReachRateByPost} data={Utils.buildCardData(reachByPost, 'Average reach rate by post')}
          helpText={`The average reach rate of the posts published within the selected dates. Reach rate for a given post = people who saw the post divided by number of followers. Includes paid and organic activity.`} />
      </div>
      <div className='chart'>
        <LineChart
          pointSurounding
          enableVerticalLine
          averageValue={'Total Follower'}
          alignment={'center'}
          title={'Follower Growth'}
          className='follower-growth'
          withFilledArea
          mainData={analysis.userFollowerHistory ? analysis.userFollowerHistory.followersHistoryItems : []} />
      </div>
    </MainFrame>
  )
}
