import React, { useEffect } from 'react';
import './index.css';
import './resp.css';
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Constants from '../../services/Constants';
import Confirmed from '../../assets/images/confirmed.png';
import ConfirmFailed from '../../assets/images/confirm_failed.png';
import LightPageWrapper from '../../components/ui/LightPageWrapper';
import IgbButton from '../../components/ui/IgbButton';

export default (props) => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)


  useEffect(() => {
    dispatch({type: 'SEND_REQUEST', data: {
      code
    }, apiType: Constants.apiTypes.activateAccount})
  }, [code, dispatch])

  return (
    <LightPageWrapper className='acount-activation-page'>
      <div className='connect-wrapper'>
        {
          user.accountActivating ?
          <div className='loading'>
            <ClipLoader
              size={60}
              color={"#0184FF"}
              loading={true}
              />
          </div> :
          <div className='connect-container'>
            <div className='avatar-wrapper'>
              <img src={user.accountActivationError ? ConfirmFailed : Confirmed} width='140' alt='ins logo' />
            </div>
            <div className='connect-content'>
              <div className='connect-title'>{user.accountActivationError ? 'Something went wrong' : 'Confirmed successfully!'}</div>
              <div className='connect-description'>
                { user.accountActivationError ? `Your link has expired or is incorrect. We can send you a new link after you log in.` : `Your account has been successfully confirmed. Please enjoy Ig Analyzer Business!` }
              </div>
            </div>
            <IgbButton className='igb-is-primary complete' isLink linkTo='/overview'>{user.accountActivationError ? 'Go back to log in' : 'OK'}</IgbButton>
          </div>
        }
      </div>
    </LightPageWrapper>
  )
}
