import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader"

export default ({ component: Component, ...rest }) => {
  const user = useSelector(state => state.user)
  const storageAware = useSelector(state => state.storageAware)
  const globalError = useSelector(state => state.globalError)

  const renderComponent = (props) => {
    if (!user.accessToken || globalError.isLoginRequired) {
      return <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    }

    if (!user.fbConnected) {
      return <Redirect to={{
        pathname: '/connect_fb',
        state: { from: props.location }
      }} />
    }

    return <Component {...props} />
  }

  return (
    <React.Fragment>
      {
        storageAware.loaded ?
          <Route {...rest} render={renderComponent} /> :
          <div style={{ position: 'fixed', zIndex: 11, top: 0, bottom: 0, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ClipLoader
              size={150}
              color={"#0184FF"}
              loading={true}
            />
          </div>
      }
    </React.Fragment>
  )

}
