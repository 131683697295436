import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import './index.css'
import './resp.css'
import IgbIconButtonWrapper from '../IgbIconButtonWrapper'
import IgbAvatarWrapper from '../IgbAvatarWrapper'
import ProfileStatus from '../ProfileStatus'
import ConfirmationModal from '../ConfirmationModal'
import ModalProfileAvatar from '../ModalProfileAvatar'
import ProfileDelete from '../../../assets/images/profile_delete.svg'

import { useDispatch, useSelector } from 'react-redux'
import Constants from '../../../services/Constants'
import localActions from '../../../_actions/local.actions'

export default (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [toDeleteProfile, setToDeleteProfile] = useState()

  const isMiddleOrLargeDevice = useMediaQuery({ query: '(min-width: 769px)' })
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const profileAccounts = props.profiles.map((profile) => {
    return {
      fbPageId: profile.fbPageId,
      name: profile.igbUser.username,
      nickName: profile.igbUser.name,
      avatar: profile.igbUser.profilePictureUrl,
      status: {
        key: 'CONNECTED',
        value: 'Connected'
      },
      type: 'Instagram Business Profile'
    }
  })

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const deleteProfile = (profile) => {
    return () => {
      setToDeleteProfile(profile)
      setIsModalOpen(true)
    }
  }

  const sendDeleteProfileRequest = () => {
    dispatch({ type: 'SEND_REQUEST', data: { fbPageId: toDeleteProfile.fbPageId }, apiType: Constants.apiTypes.cancelConnectedFbPage })
    dispatch(localActions.selectProfile({ selectedPage: null }))
    if (!user.connectedFbCanceling && !user.cancelConnectedFbError) {
      setIsModalOpen(false)
    }
  }

  const buildAccounts = () => {
    return profileAccounts.map((pa, i) => {
      return (
        <tr key={i}>
          <td>
            <div className='account-block'>
              <IgbAvatarWrapper className='avatar' withInsTag={pa.type === 'Instagram Business Profile'} size='large'>
                <img src={pa.avatar} alt='' />
              </IgbAvatarWrapper>
              <div>
                <div>{pa.name}</div>
                <div>{pa.nickName}</div>
              </div>
            </div>
          </td>
          <td>
            <ProfileStatus status={pa.status} type={pa.type} />
          </td>
          <td className='actions'>
            <IgbIconButtonWrapper onClick={deleteProfile(pa)}>
              <img className='trash' src={ProfileDelete} alt='' />
            </IgbIconButtonWrapper>
          </td>
        </tr>
      )
    })
  }

  const buildAccountCards = () => {
    return profileAccounts.map((pa, i) => {
      return (
        <div className='account-card' key={i}>
          <IgbIconButtonWrapper className='remove-button' onClick={deleteProfile(pa)}>
            <img className='trash' src={ProfileDelete} alt='' />
          </IgbIconButtonWrapper>
          <div className='card-start'>
            <IgbAvatarWrapper className='avatar' withInsTag={pa.type === 'Instagram Business Profile'} size='large'>
              <img src={pa.avatar} alt='' />
            </IgbAvatarWrapper>
            <div className='names'>
              <div>{pa.name}</div>
              <div>{pa.nickName}</div>
            </div>
          </div>
          <div className='card-end'>
            <ProfileStatus status={pa.status} type={pa.type} />
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        primaryAction={sendDeleteProfileRequest}
        secondaryAction={closeModal}
        buttonTextPrimary='confirm'
        buttonTextSecondary='cancel'>
        <ModalProfileAvatar type='minus' />
        {user.cancelConnectedFbError ? <div className='modal error-text'>Something went wrong</div> : null}
        <div className='modal-title'>Are you sure you want to remove this account?</div>
        <div className='modal-description'>You can reconnect this account at any time.</div>
      </ConfirmationModal>
      {
        isMiddleOrLargeDevice ?
          <table className='accounts-table'>
            <thead>
              <tr>
                <th>Account</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {buildAccounts()}
            </tbody>
          </table> :
          <div className='account-cards'>
            {buildAccountCards()}
          </div>
      }
    </div>
  )
}
