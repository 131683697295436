import ReconnectingWebSocket from 'reconnecting-websocket'
import Constants from '../services/Constants'
import messageParser from '../_helpers/messageParser'
import requestActions from '../_helpers/requestActions'
import { setIsLoginRequired } from '../_actions'
import Utils from '../services/Utils'

const websocketMiddleware = () => {
  let socket = new ReconnectingWebSocket(Constants.envs[process.env.NODE_ENV].WEB_SOCKET_URL)
  const GLOBAL_ERRORS = ['AuthError', 'InvalidToken', 'TokenExpired']

  const onMessage = (store) => (event) => {
    messageParser.getResponseObject(event.data).then((result) => {
      const responseKey = Utils.camelize(`${result.apiType}Response`)
      store.dispatch(requestActions.requestSuccess(result.apiType, result[responseKey]))
    }, (errMessage) => {
      if (GLOBAL_ERRORS.includes(errMessage.error.code)) {
        store.dispatch(setIsLoginRequired(true))
      }
      store.dispatch(requestActions.requestFailure(errMessage.apiType, errMessage.error))
    })
  }

  return (store) => {
    socket.addEventListener('message', onMessage(store))
    return (next) => {
      return (action) => {
        switch (action.type) {
          case 'SEND_REQUEST':
            messageParser.buildMessage(action.data, action.apiType, store.getState().user.accessToken).then((message) => {
              socket.send(message)
              store.dispatch(requestActions.requesting(action.apiType, action.data))
            })
            break
          default:
            return next(action)
        }
      }
    }
  }
}

export default websocketMiddleware()
