import React, { forwardRef } from 'react';
import './index.css';
import './resp.css';
import MediaPlay from '../../../assets/images/media_play.svg';
import MediaLikes from '../../../assets/images/media_likes.svg';
import MediaComments from '../../../assets/images/media_comments.svg';

export default forwardRef((props, ref) => {
  const { media } = props;

  return (
    <div className='media-item' ref={ref}>
      <div className='head'>
        <img src={media.igbUserAvatar} className='avatar' alt='' />
        <div>{media.durationText}ago</div>
      </div>
      <div className='body'>
        {
          media.mediaType === 2 ?
            <video className='media-content' src={media.mediaUrl} /> :
            <img className='media-content' src={media.mediaUrl} alt='' />
        }
      </div>
      <div className='foot'>
        {
          media.mediaType === 2 ?
            <div className='media-reaction'><img src={MediaPlay} alt='' />229</div> : null
        }
        <div className='media-reaction'><img src={MediaLikes} alt='' />{media.likeCount}</div>
        <div className='media-reaction'><img src={MediaComments} alt='' />{media.commentsCount}</div>
      </div>
    </div>
  )
})
