import React, { useState, useEffect } from 'react';
import './index.css';
import './resp.css';
import SidebarLogout from '../../../assets/images/sidebar_logout.svg';
import SidebarLogoutActive from '../../../assets/images/sidebar_logout_active.svg';
import SidebarOverview from '../../../assets/images/sidebar_overview.svg';
import SidebarOverviewActive from '../../../assets/images/sidebar_overview_active.svg';
import SidebarEngagement from '../../../assets/images/sidebar_engagement.svg';
import SidebarEngagementActive from '../../../assets/images/sidebar_engagement_active.svg';
import SidebarStories from '../../../assets/images/sidebar_stories.svg';
import SidebarStoriesActive from '../../../assets/images/sidebar_stories_active.svg';
import SidebarSettings from '../../../assets/images/sidebar_settings.svg';
import SidebarSettingsActive from '../../../assets/images/sidebar_settings_active.svg';

import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Constants from '../../../services/Constants';
import useActiveLinkPath from '../../hooks/useActiveLinkPath';
import AddMoreProfileButton from '../AddMoreProfileButton';

export default (props) => {
  const [ isNavbarExpanded, setIsNavbarExpanded ] = useState(false);
  const [ isMouseEnter, setIsMouseEnter ] = useState(null)
  const [ hoveredLinkName, setHoveredLinkName ] = useState(null);
  const activeLinkPath = useActiveLinkPath();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isOuterControl) {
      setIsNavbarExpanded(props.isExpanded)
    }
  }, [props.isOuterControl, props.isExpanded])

  const onContainerMouseEnter = () => {
    setIsNavbarExpanded(true)
    setIsMouseEnter(true)
  }

  const onContainerMouseLeave = () => {
    setIsNavbarExpanded(false)
    setIsMouseEnter(false)
  }

  const baseLinkClassName = `navbar-item ${isNavbarExpanded ? 'is-expanded' : ''}`
  const pageLinks = [
    {
      name: 'Overview',
      path: '/overview',
      iconSrc: SidebarOverview,
      activeIconSrc: SidebarOverviewActive
    },
    {
      name: 'Engagement',
      path: '/engagement',
      iconSrc: SidebarEngagement,
      activeIconSrc: SidebarEngagementActive
    },
    {
      name: 'Stories',
      path: '/stories',
      iconSrc: SidebarStories,
      activeIconSrc: SidebarStoriesActive
    },
    {
      name: 'Settings',
      path: '/settings',
      iconSrc: SidebarSettings,
      activeIconSrc: SidebarSettingsActive
    }
  ]

  const onMouseEnterLink = (linkName) => {
    return () => {
      setHoveredLinkName(linkName)
    }
  }

  const onMouseLeaveLink = () => {
    setHoveredLinkName(null)
  }

  const generatePageLinks = () => {
    return pageLinks.map((pl, i) => {
      return (
        <Link key={i} onMouseEnter={onMouseEnterLink(pl.name)} onMouseLeave={onMouseLeaveLink} onClick={() => {setIsNavbarExpanded(false)}} className={`${baseLinkClassName} ${activeLinkPath === pl.name.toUpperCase() ? 'is-link-active' : ''}`} to={pl.path} >
          <img alt='' className='icon' height='28' src={(activeLinkPath === pl.name.toUpperCase() || hoveredLinkName === pl.name ) ? pl.activeIconSrc: pl.iconSrc} />
          <div className='link-text'>{pl.name}</div>
        </Link>
      )
    })
  }

  const logout = () => {
    dispatch({type: 'SEND_REQUEST', data: {}, apiType: Constants.apiTypes.logout})
  }

  return (
    <div>
      { props.hideNav ? <div className='nav-curtain' onClick={props.hideNav}></div> : null }
      <div className={`nav-container ${isNavbarExpanded ? 'is-expanded' : ''} ${isMouseEnter && 'is-mouse-enter'}  ${isMouseEnter === false && 'is-mouse-leave'}`}
        onMouseEnter={props.isOuterControl ? null : onContainerMouseEnter}
        onMouseLeave={props.isOuterControl ? null : onContainerMouseLeave}>

        <div className='nav-start'>
          <div className='nav-brand'>
            <Link className={`${baseLinkClassName} logo`} to='/overview'>
              <div className='bars'>
                <div className='line'></div>
                <div className='line'></div>
                <div className='line'></div>
              </div>
              <div className='link-text'>Ig Analyzer Business</div>
            </Link>
          </div>
          <div className='nav-page-links'>
            <AddMoreProfileButton className='add-profiles' />
            { generatePageLinks() }
          </div>
        </div>
        <div className='nav-end'>
          <Link onMouseEnter={onMouseEnterLink('Logout')} onClick={logout} onMouseLeave={onMouseLeaveLink} className={`${baseLinkClassName}`} to='/login'>
            <img className='icon' alt='' src={hoveredLinkName === 'Logout' ? SidebarLogoutActive : SidebarLogout }  height="28" />
            <div className='link-text'>Log out</div>
          </Link>
        </div>
      </div>
    </div>
  )
}
