import { combineReducers } from 'redux';

import user from './user.reducers';
import websocket from './websocket.reducers';
import analysis from './analysis.reducers';
import storageAware from './storageAware.reducers';
import local from './local.reducers';
import globalError from './globalError.reducers';

const rootReducer = combineReducers({
  user,
  websocket,
  analysis,
  storageAware,
  local,
  globalError
});

export default rootReducer;
