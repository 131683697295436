import React, { useEffect, useContext } from 'react'
import './index.css'
import './resp.css'
import MainFrame from '../../components/ui/MainFrame'
import ThinSummaryCard from '../../components/ui/ThinSummaryCard'
import LineChart from '../../components/ui/LineChart'
import PageContentTitleLine from '../../components/ui/PageContentTitleLine'
import { LocaleContext } from '../../services/Locales'
import StoriesImpressionTotal from '../../assets/images/stories_impression_total.svg'
import StoriesAvgImpression from '../../assets/images/stories_avg_impression.svg'
import StoriesReachTotal from '../../assets/images/stories_reach_total.svg'
import StoriesAvgReach from '../../assets/images/stories_avg_reach.svg'
import StoriesTotal from '../../assets/images/stories_total.svg'
import StoriesTotalPhoto from '../../assets/images/stories_total_photo.svg'
import StoriesTotalVideo from '../../assets/images/stories_total_video.svg'
import StoriesAvgPerDay from '../../assets/images/stories_avg_per_day.svg'
import { useDispatch, useSelector } from 'react-redux'
import Constants from '../../services/Constants'
import Utils from '../../services/Utils'
import { setDateRange } from '../../_actions/local.actions'

export default (props) => {
  const locale = useContext(LocaleContext)
  const user = useSelector(state => state.user)
  const local = useSelector(state => state.local)
  const analysis = useSelector(state => state.analysis)
  const dispatch = useDispatch()

  useEffect(() => {
    const baseParams = Utils.buildBaseParams(local, user)
    if (baseParams) {
      dispatch({ type: 'SEND_REQUEST', data: baseParams, apiType: Constants.apiTypes.fetchIgbStoryMetric })
      dispatch({ type: 'SEND_REQUEST', data: baseParams, apiType: Constants.apiTypes.fetchIgbStoryReachHistory })
      dispatch({ type: 'SEND_REQUEST', data: baseParams, apiType: Constants.apiTypes.fetchIgbStoryImpressionHistory })
    }
  }, [local, user, dispatch])

  const updateDateRange = (startDate, endDate) => {
    dispatch(setDateRange({
      sDate: startDate,
      eDate: endDate
    }))
  }

  const { impression, impressionPerStory, photo, reach, reachPerStory, storiesPerDay, storiesPosted, video } = analysis.storyMetric || {}

  return (
    <MainFrame className='stories'>
      <PageContentTitleLine startDate={local.dateRange.sDate} endDate={local.dateRange.eDate} updateDates={updateDateRange} contentTitle={locale['pages']['stories']['title']} />
      <div className='summaries'>
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesImpressionTotal} data={Utils.buildCardData(impression, 'Impression total')}
          helpText={`Total number of Stories' impressions over the chosen period.`} />
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesAvgImpression} data={Utils.buildCardData(impressionPerStory, 'Average impression per story')}
          helpText={`Average number of impressions per Story over the chosen period. `} />
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesReachTotal} data={Utils.buildCardData(reach, 'Reach total')}
          helpText={`Total number of Stories' reach over the chosen period.`} />
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesAvgReach} data={Utils.buildCardData(reachPerStory, 'Average reach per story')}
          helpText={`Average reach per Story over the chosen period. `} />
      </div>
      <div className='charts'>
        <LineChart
          rotateTicksX
          className='history reach'
          title={'Reach History'}
          divideLine
          alignment={'left'}
          mainInstruction={{ text: 'Reach of the day', width: 102 }}
          secondaryInstruction={{ text: 'Daily average reach', width: 119 }}
          firstLabel={'Reach'}
          secondLabel={'Average'}
          margin={{ top: 145, right: 50, bottom: 60, left: 60 }}
          averageValue={analysis.storyReachHistory ? analysis.storyReachHistory.average : 0}
          mainData={analysis.storyReachHistory ? analysis.storyReachHistory.reachHistoryItems : []}
          secondaryData={analysis.storyReachHistory ? analysis.storyReachHistory.reachHistoryItems.map((item) => {
            return {
              value: analysis.storyReachHistory.average,
              date: item.date
            }
          }) : []}
          mainLine={{ strokeColor: '#FFBC01' }}
          point={{ strokeColor: '#FFF', fillColor: '#FFBC01' }}
          secondaryLine={{ strokeColor: '#D6DDE1' }} />
        <LineChart
          rotateTicksX
          className='history impression'
          title={'Impression History'}
          divideLine
          alignment={'left'}
          mainInstruction={{ text: 'Impression of the day', width: 131 }}
          secondaryInstruction={{ text: 'Daily average impression', width: 151 }}
          firstLabel={'Impression'}
          secondLabel={'Average'}
          margin={{ top: 145, right: 50, bottom: 60, left: 60 }}
          averageValue={analysis.storyImpressionHistory ? analysis.storyImpressionHistory.average : 0}
          mainData={analysis.storyImpressionHistory ? analysis.storyImpressionHistory.impressionHistoryItems : []}
          secondaryData={analysis.storyImpressionHistory ? analysis.storyImpressionHistory.impressionHistoryItems.map((item) => {
            return {
              value: analysis.storyImpressionHistory.average,
              date: item.date
            }
          }) : []}
          mainLine={{ strokeColor: '#876BFE' }}
          point={{ strokeColor: '#FFF', fillColor: '#876BFE' }}
          secondaryLine={{ strokeColor: '#D6DDE1' }} />
      </div>
      <div className='summaries'>
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesTotal} data={Utils.buildCardData(storiesPosted, 'Total stories posted')}
          helpText={`Total number of Stories, over the chosen period. `} />
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesTotalPhoto} data={Utils.buildCardData(photo, 'Total photo')}
          helpText={`Total number of photo Stories, over the chosen period. `} />
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesTotalVideo} data={Utils.buildCardData(video, 'Total video')}
          helpText={`Total number of video Stories, over the chosen period. `} />
        <ThinSummaryCard metric={'storyMetric'} catIcon={StoriesAvgPerDay} data={Utils.buildCardData(storiesPerDay, 'Average stories per day')}
          helpText={`Average number of Stories per day over the chosen period. `} />
      </div>
    </MainFrame>
  )
}
