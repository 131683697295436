import React from 'react';
import './index.css';
import HelpIcon from '../../../assets/images/help_icon.png';

export default (props) => {
  return (
    <div className={`igb-tooltip ${props.className || ''}`}>
      <img src={HelpIcon} className={`tooltip-icon ${props.iconClassName || ''}`} alt='' />
      <div className='tt-box'>
        <div className='arrow'></div>
        <div className={`text-box ${props.customTextBox || ''}`}>{ props.children }</div>
      </div>
    </div>
  )
}
