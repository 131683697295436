import React, { useState, useEffect } from 'react'
import './index.css'
import './resp.css'
import IgbDateRangePicker from '../IgbDateRangePicker'
import { useSelector } from 'react-redux'
import useMediaQuery from 'react-responsive'

export default (props) => {
  const [isDatePickerFixed, setIsDatePickerFixed] = useState(false)
  const local = useSelector(state => state.local)
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' })
  const isIpadDevice = useMediaQuery({ query: '(max-width: 768px)' })
  const isIpadProDevice = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobileLandscape = useMediaQuery({ query: '(max-width: 896px, orientation: landscape)' })

  useEffect(() => {
    const getEmailPanelHeight = () => {
      if (isMobileDevice) {
        return 197
      }
      if (isIpadDevice) {
        return 203
      }
      if (isIpadProDevice) {
        return 167
      }
      if (isMobileLandscape) {
        return 161
      }
      return 286
    }

    const handleScroll = (e) => {
      const mutationPoint = local.isResendEmailPanelVisible ? getEmailPanelHeight() : 0
      if (window.pageYOffset > mutationPoint) {
        setIsDatePickerFixed(true)
      } else {
        setIsDatePickerFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [local, isMobileDevice, isIpadDevice, isIpadProDevice, isMobileLandscape])

  return (
    <div className='page-content-line' >
      <div className='content-title'>{props.contentTitle}</div>
      <div className={`date-picker-container ${isDatePickerFixed ? 'position-fixed' : 'position-relative'}`}>
        <IgbDateRangePicker {...props} />
      </div>
    </div>
  )
}
