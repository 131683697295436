import React from 'react';
import './index.css';
import './resp.css';
import BaseModal from '../BaseModal';
import EmailAvatar from '../../../assets/images/email_avatar.jpg';

export default (props) => {

  return (
    <BaseModal {...props} buttonText={'OK'} onClick={props.closeModal}>
      <img className='avatar' src={EmailAvatar} alt='cartoon boy' />
      <div className='modal-title'>A confirmation email has been sent to you!</div>
      <div className='modal-description'>Confirmation email has been sent to your email {props.email || 'iganalyzer@business.com'}. Please follow the instructions in the email to confirm your account.</div>
    </BaseModal>
  )
}
