import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './index.css';
import './resp.css';
import IgbFormField from '../../components/ui/IgbFormField';
import IgbButton from '../../components/ui/IgbButton';
import LightPageWrapper from '../../components/ui/LightPageWrapper';

import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";
import Constants from '../../services/Constants';
import FormMessage from '../../components/ui/FormMessage';
import userActions from '../../_actions/user.actions';
import ResetPasswordConfirmModal from '../../components/ui/ResetPasswordConfirmModal';

export default () => {
  const [ errorMessage, setErrorMessage ] = useState();
  const [ infoMessage, setInfoMessage ] = useState();
  const [isResettingConfirmOpen, setIsResettingConfirmOpen] = useState(false)

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { code } = useParams();

  useEffect(() => {
    if (!user.resetPasswordError && user.passwordResetting) {
      setIsResettingConfirmOpen(true)
    }
  }, [user.resetPasswordError, user.passwordResetting])

  useEffect(() => {
    if (user.resetPasswordCodeError) {
      setErrorMessage(user.resetPasswordCodeError)
    }
    else if (user.resetPasswordEmailSending === false) {
      setInfoMessage('The email has been sent successfully, please follow the instructions in the email to change your password.')
    }
  }, [user.resetPasswordEmailSending, user.resetPasswordCodeError])

  useEffect(() => {
    if (user.resetPasswordError) {
      setErrorMessage(user.resetPasswordError)
    }
  }, [user.resetPasswordError])

  const handleCloseResetingConfirm = () => {
    setIsResettingConfirmOpen(false)
  }

  const onSendActivateEmail = (values) => {
    dispatch({type: 'SEND_REQUEST', data: values, apiType: Constants.apiTypes.sendAccountResetPasswordCode})
  }

  const handleResetPasswordSubmit = values => {
    dispatch({type: 'SEND_REQUEST', data: {
      code,
      password: values.password.trim()
    }, apiType: Constants.apiTypes.resetAccountPassword})
  }

  const onCloseMessage = () => {
    setErrorMessage(null)
    setInfoMessage(null)
    dispatch(userActions.sendAccountResetPasswordCodeFailure(null))
    dispatch(userActions.resetAccountPasswordFailure(null))
  }

  return (
    <LightPageWrapper className='reset-password-container'>
      <ResetPasswordConfirmModal isModalOpen={isResettingConfirmOpen} closeModal={handleCloseResetingConfirm} />
      <div className='content-wrapper'>
        <div className='content'>
          <div className='reset-pw'>Reset your password</div>
          {errorMessage ? <FormMessage type='error' closeMessage={onCloseMessage}>{errorMessage}</FormMessage> : null}
          {infoMessage ? <FormMessage type='info' closeMessage={onCloseMessage}>{infoMessage}</FormMessage> : null}
          { !code ? <div className='instruction'>Please enter the email you use to log in to Ig Analyzer Business. We will send you an email to help you reset your password.</div> : null }
          {
            !code ?
            <Formik
              initialValues={{
                email: ''
              }}
              onSubmit={onSendActivateEmail}
              validationSchema={yup.object().shape({
                email: yup.string()
                  .email("Must be valid email")
                  .required("Required")
              })}
              >
              {
                props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <IgbFormField
                        inputClassName={errors.email && touched.email ? "error" : ""}
                        showError={errors.email && touched.email}
                        error={errors.email}
                        label='Email Address'
                        inputName={'email'}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <div className='actions'>
                        <IgbButton className='igb-is-primary send-button' disabled={JSON.stringify(errors) !== '{}' || user.resetPasswordEmailSending || !values.email} buttonType={'submit'}>
                          {
                            user.resetPasswordEmailSending === true ?
                            <ClipLoader
                              size={30}
                              color={"#FFF"}
                              loading={true}
                            /> :  'Send the Email'
                          }
                        </IgbButton>
                        <Link className={`sign-in`} to='/login'>Go back to log in</Link>
                      </div>
                    </form>
                  )
                }
              }
            </Formik> :
            <Formik
              initialValues={{
                password: '',
                passwordConfirm: ''
              }}
              onSubmit={handleResetPasswordSubmit}
              validationSchema={yup.object().shape({
                password: yup.string()
                  .required("No password provided.")
                  .min(8, "Password should be 8 chars minimum.")
                  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "must contain number and Uppercase&lowercase letter"),
                passwordConfirm: yup.string().equalTo(yup.ref('password'), 'Passwords must match').required('Required')
              })}
              >
              {
                props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <IgbFormField
                        className='password new'
                        inputClassName={errors.password && touched.password ? "error" : ""}
                        showError={errors.password && touched.password}
                        error={errors.password}
                        inputType='password'
                        label='Password'
                        inputName={'password'}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <IgbFormField
                        className='password confirm'
                        inputClassName={errors.passwordConfirm && touched.passwordConfirm ? "error" : ""}
                        showError={errors.passwordConfirm && touched.passwordConfirm}
                        error={errors.passwordConfirm}
                        inputType='password'
                        label='Confirm Password'
                        inputName={'passwordConfirm'}
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <div className='actions'>
                        <IgbButton className='igb-is-primary send-button' disabled={JSON.stringify(errors) !== '{}' || !(values.password && values.passwordConfirm)} buttonType={'submit'}>
                          {
                            user.passwordResetting === true ?
                            <ClipLoader
                              size={30}
                              color={"#FFF"}
                              loading={true}
                            /> : 'Reset my password'
                          }
                        </IgbButton>
                        <Link className={`sign-in`} to='/login'>Go back to log in</Link>
                      </div>
                    </form>
                  )
                }
              }
            </Formik>
          }
        </div>
      </div>
    </LightPageWrapper>
  )
}
