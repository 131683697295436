import React, { useState, useEffect } from 'react'
import './index.css'
import './resp.css'
import MainFrame from '../../components/ui/MainFrame'
import AddProfileModal from '../../components/ui/AddProfileModal'
import IgbFormField from '../../components/ui/IgbFormField'
import IgbButton from '../../components/ui/IgbButton'
import SocialProfiles from '../../components/ui/SocialProfiles'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Formik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from 'react-redux'
import FormMessage from '../../components/ui/FormMessage'
import Constants from '../../services/Constants'
import ConfirmEmailPanel from '../../components/ui/ConfirmEmailPanel'
import ClipLoader from "react-spinners/ClipLoader"

export default () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [errorPwMessage, setErrorPwMessage] = useState()
  const [infoPwMessage, setInfoPwMessage] = useState()

  const user = useSelector(state => state.user)
  const local = useSelector(state => state.local)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.updateProfileError) {
      setErrorPwMessage((
        <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <div className='form-message-title error'>Oops!</div>
          <div>{user.updateProfileError.msgs}</div>
        </div>
      ))
    }
  }, [user])

  useEffect(() => {
    if (user.profileUpdateSuccessMsg) {
      setErrorPwMessage((
        <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <div className='form-message-title info'>Success!</div>
          <div>{user.profileUpdateSuccessMsg}</div>
        </div>
      ))
    }
  }, [user])

  const handleUpdateProfilePwSubmit = values => {
    setErrorPwMessage(null)
    dispatch({ type: 'SEND_REQUEST', data: { password: values.password }, apiType: Constants.apiTypes.updateProfile })
  }

  const addSocialProfile = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const connectFbCallback = (res) => {
    if (res.authResponse) {
      dispatch({ type: 'SEND_REQUEST', data: { fbPageAccessToken: res.authResponse.accessToken }, apiType: Constants.apiTypes.connectFbPage })
    }
    closeModal()
  }

  return (
    <MainFrame className='custom-main-frame' hideEmailComfirmPanel>
      <AddProfileModal fbLoginCallback={connectFbCallback} isModalOpen={isModalOpen} afterOpenModal={() => { }} closeModal={closeModal} />
      <Tabs>
        <TabList>
          <Tab>Security</Tab>
          <Tab>Social Profile</Tab>
        </TabList>
        <TabPanel>
          {(local.isManuallyClosedEmailPanel || !local.isResendEmailPanelVisible) ? null : <ConfirmEmailPanel className="at-settings-page" />}
          <div className='panel-name'>Change password</div>
          <div className='panel-description'>To change your password, please fill in the fields below. Your password must contain at least 8 characters.</div>
          {errorPwMessage ? <FormMessage className='profile-form-message' hideErrorPrefix={true} type='error' closeMessage={() => { setErrorPwMessage(null) }}>{errorPwMessage}</FormMessage> : null}
          {infoPwMessage ? <FormMessage className='profile-form-message' type='info' closeMessage={() => { setInfoPwMessage(null) }}>{infoPwMessage}</FormMessage> : null}
          <div className='form-container igb-form-container'>
            <Formik
              initialValues={{
                oldPassword: '',
                password: '',
                passwordConfirm: ''
              }}
              onSubmit={handleUpdateProfilePwSubmit}
              validationSchema={yup.object().shape({
                oldPassword: yup.string().required('Required'),
                password: yup.string()
                  .required("No password provided.")
                  .min(8, "Password should be 8 chars minimum.")
                  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "must contain number and Uppercase&lowercase letter"),
                passwordConfirm: yup.string().equalTo(yup.ref('password'), 'Passwords must match').required('Required')
              })}
            >
              {
                props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props
                  return (
                    <form onSubmit={handleSubmit}>
                      <IgbFormField
                        inputClassName={errors.oldPassword && touched.oldPassword ? "error" : ""}
                        showError={errors.oldPassword && touched.oldPassword}
                        error={errors.oldPassword}
                        isRequired
                        inputType='password'
                        label='Old password'
                        inputName={'oldPassword'}
                        value={values.oldPassword}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <IgbFormField
                        inputClassName={errors.password && touched.password ? "error" : ""}
                        showError={errors.password && touched.password}
                        error={errors.password}
                        isRequired
                        inputType='password'
                        label='Password'
                        inputName={'password'}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <IgbFormField
                        inputClassName={errors.passwordConfirm && touched.passwordConfirm ? "error" : ""}
                        showError={errors.passwordConfirm && touched.passwordConfirm}
                        error={errors.passwordConfirm}
                        isRequired
                        inputType='password'
                        label='Confirm Password'
                        inputName={'passwordConfirm'}
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <div className='action-button-container'>
                        <IgbButton className='igb-is-primary' disabled={!values.oldPassword || !values.password || !values.passwordConfirm} buttonType={'submit'}>
                          {
                            user.profileUpdating ?
                              <ClipLoader
                                size={30}
                                color={"#FFF"}
                                loading={true}
                              /> : 'Change password'
                          }
                        </IgbButton>
                      </div>
                    </form>
                  )
                }
              }
            </Formik>
          </div>
        </TabPanel>
        <TabPanel>
          {(local.isManuallyClosedEmailPanel || !local.isResendEmailPanelVisible) ? null : <ConfirmEmailPanel className="at-settings-page" />}
          <div className='panel-name'>Social profiles</div>
          <SocialProfiles profiles={user.account.fbPages} />
          <div className='action-button-container'>
            <IgbButton className='igb-is-primary' onClick={addSocialProfile}>Add Social profile(s)</IgbButton>
          </div>
        </TabPanel>
      </Tabs>
    </MainFrame>
  )
}
