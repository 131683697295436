import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import './index.css'
import './resp.css'
import ConfirmEmailPanel from '../ConfirmEmailPanel'
import IgbAvatarWrapper from '../IgbAvatarWrapper'
import Navbar from '../Navbar'
import AddMoreProfileButton from '../AddMoreProfileButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WaitingModal from '../WaitingModal'
import localActions from '../../../_actions/local.actions'
import useActiveLinkPath from '../../hooks/useActiveLinkPath'

export default (props) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAuthenticated] = useState(false)

  const user = useSelector(state => state.user)
  const local = useSelector(state => state.local)
  const dispatch = useDispatch()
  const activeLinkPath = useActiveLinkPath()
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' })

  const EMAIL_PANEL_EXCLUDE_PATH = ['SETTINGS']

  const getCheckedKey = useCallback(() => {
    let currentPage = local.selectedPage || user.account.fbPages[0]
    return `CHECKED_PAGE_${currentPage.fbPageId}`
  }, [local, user])

  useEffect(() => {
    const isChecked = localStorage.getItem(getCheckedKey())
    setIsModalOpen(isChecked !== 'true')
  }, [getCheckedKey])

  useEffect(() => {
    dispatch(localActions.updateEmailPanelState({ isVisible: !isAuthenticated }))
  }, [isAuthenticated, dispatch])

  const controlNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible)
  }

  const onSelectProfile = (fbPage) => {
    return () => {
      dispatch(localActions.selectProfile({ selectedPage: fbPage }))
    }
  }

  const buildAccountAvatars = (fbPages) => {
    let selected = local.selectedPage || fbPages[0]

    return fbPages.map((fp, i) => {
      return (
        <div className={`avatar ${selected.igbUser.igbId === fp.igbUser.igbId ? 'selected' : ''}`} onClick={onSelectProfile(fp)} key={i}>
          <IgbAvatarWrapper className='wrapper' withInsTag size='small' >
            <img className='pic' src={fp.igbUser.profilePictureUrl} alt='avatar' />
          </IgbAvatarWrapper>
          <div className='hover-text'>{fp.igbUser.name}</div>
        </div>
      )
    })
  }

  const hideNav = () => {
    setIsNavbarVisible(false)
  }

  const handleWaitingModalClose = () => {
    setIsModalOpen(false)
    localStorage.setItem(getCheckedKey(), 'true')
  }

  return (
    <div className='page-container'>
      {!isMobileDevice ? <Navbar /> : (isNavbarVisible ? <Navbar hideNav={hideNav} isOuterControl isExpanded /> : null)}
      <WaitingModal isModalOpen={isModalOpen} afterOpenModal={() => { }} closeModal={handleWaitingModalClose} />
      <div className='right-main'>
        <div className='head-line'>
          <div className='start'>
            {
              isMobileDevice ?
                <div className='nav-control'>
                  <button className='mobile-menu' onClick={controlNavbar}>
                    {
                      isNavbarVisible ? <FontAwesomeIcon style={{ width: '20px' }} color='#7F89A1' icon={'times'} /> :
                        <FontAwesomeIcon style={{ width: '20px' }} color='#7F89A1' icon={'bars'} />
                    }
                  </button>
                  <div>Ig Analyzer Business</div>
                </div> : null
            }
          </div>
          <div className='end'>
            <div className='avatars'>
              {buildAccountAvatars(user.account.fbPages)}
              <AddMoreProfileButton className='top-add-profiles' />
            </div>
          </div>
        </div>
        {(local.isManuallyClosedEmailPanel || !local.isResendEmailPanelVisible || EMAIL_PANEL_EXCLUDE_PATH.includes(activeLinkPath)) ? null : <ConfirmEmailPanel />}
        <div className={`content-container ${props.className}`}>
          {props.children}
        </div>
      </div>
    </div>
  )
}
