import React, { useState } from 'react';
import './index.css';
import IgbTooltip from '../IgbTooltip';
import IgbIconButtonWrapper from '../IgbIconButtonWrapper';
import EyeOpen from '../../../assets/images/eye_open.png';
import EyeClose from '../../../assets/images/eye_close.png';

export default (props) => {
  const [ isPasswordVisible, setIsPasswordVisible ] = useState(false)
  return (
    <div className={`field igb-field ${props.isHalfWidth ? 'half-width' : ''} ${props.className || ''}`}>
      <label className={`label igb-label`}>
        <div className='start'>
          {props.label}
          {
            props.isRequired ? <span className='required-asterisk'>*</span> : null
          }
          {
            props.helpInfo ?
            <IgbTooltip className='form-label-tooltip' iconClassName='form-help-icon'>
              {props.helpInfo}
            </IgbTooltip> : null
          }
        </div>
        <div className='end'>
          { props.showError ? <div className='error-text'>{props.error}</div> : null }
        </div>
      </label>
      <div className='control'>
        <div className={`input igb-form-input ${props.inputClassName || ''}`}>
          <input
            className={'real-input'}
            style={{width: props.inputType === 'password' ? '95%' : '100%'}}
            id={props.id}
            name={props.inputName}
            type={isPasswordVisible ? 'text' : (props.inputType || `text`)}
            value={props.value}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onChange={props.onChange} />
          { props.inputType === 'password' ?
            <IgbIconButtonWrapper className={`password-eye`} onClick={() => {setIsPasswordVisible(!isPasswordVisible)}}>
              {isPasswordVisible ? <img src={EyeOpen} width='18' height='14' alt='' /> : <img src={EyeClose} width='18' height='9' alt='' /> }
            </IgbIconButtonWrapper> : null
          }
        </div>
      </div>
    </div>
  )
}
