import React from 'react';
import { useHistory } from "react-router-dom";
import './index.css';
import BaseModal from '../BaseModal';
import ResetPwConfirm from '../../../assets/images/reset_pw_confirm.png'

export default (props) => {
  let history = useHistory();

  function handleLogin() {
    history.push("/login");
  }

  return (
    <BaseModal {...props}
      buttonText={'Log in'}
      onClick={handleLogin} >
      <img className='avatar' src={ResetPwConfirm} alt='checked' />
      <div className='modal-title'>Confirmation</div>
      <div className='modal-description'>{`Your password has been changed successfully. Please log in with your new password.`}</div>
    </BaseModal>
  )
}
