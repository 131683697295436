import React from 'react';
import './index.css';
import './resp.css';
import BaseModal from '../BaseModal';
import ModalProfileAvatar from '../ModalProfileAvatar'

export default (props) => {

  const connectToFb = () => {
    window.FB.login(function(response) {
      props.fbLoginCallback(response)
    }, {
      scope: 'pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,email',
      auth_type: 'reauthenticate'
    });
  }

  return (
    <BaseModal {...props} buttonText={'Connect to your Instagram account'} onClick={connectToFb}>
      <ModalProfileAvatar type='add' />
      <div className='modal-title'>Add your social profiles</div>
      <div className='modal-description'>You will be redirected to Facebook in the following step. Select the Instagram Business profiles or Facebook Pages you'd like to connect!</div>
    </BaseModal>
  )
}
