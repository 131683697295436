import { localConstants, userConstants } from '../_action_constants'
import Utils from '../services/Utils'

export default function local(state = {
  dateRange: {
    sDate: Utils.getDefaultStartDate(),
    eDate: Utils.getDefaultEndDate()
  }
}, action) {
  switch (action.type) {
    case localConstants.UPDATE_EMAIL_PANEL_STATE:
      return {
        ...state,
        isResendEmailPanelVisible: action.payload.isVisible || state.isResendEmailPanelVisible,
        isManuallyClosedEmailPanel: action.payload.isManuallyClosedEmailPanel || state.isManuallyClosedEmailPanel
      }
    case localConstants.SELECT_PROFILE:
      return {
        ...state,
        selectedPage: action.payload.selectedPage
      }
    case localConstants.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload
      }
    case userConstants.CONNECT_FB_SUCCESS:
      const dataLoadingPages = action.data.account.fbPages.filter((fp) => { return fp.connectState === 'PBFbPageConnectStateWaiting' })
      return {
        ...state,
        selectedPage: dataLoadingPages[0]
      }
    default:
      return state
  }
}
