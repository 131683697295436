import { analysisConstants } from '../_action_constants';

export default {
  userMetricRequest,
  userMetricSuccess,
  userMetricFailure,
  userFollowersHistoryRequest,
  userFollowersHistorySuccess,
  userFollowersHistoryFailure,
  mediaMetricRequest,
  mediaMetricSuccess,
  mediaMetricFailure,
  mediaLikesHistoryRequest,
  mediaLikesHistorySuccess,
  mediaLikesHistoryFailure,
  mediaCommentHistoryRequest,
  mediaCommentHistorySuccess,
  mediaCommentHistoryFailure,
  mediaMostActionsListRequest,
  mediaMostActionsListSuccess,
  mediaMostActionsListFailure,
  storyMetricRequest,
  storyMetricSuccess,
  storyMetricFailure,
  storyReachHistoryRequest,
  storyReachHistorySuccess,
  storyReachHistoryFailure,
  storyImpressionHistoryRequest,
  storyImpressionHistorySuccess,
  storyImpressionHistoryFailure
}

function userMetricRequest(requestData) { return { type: analysisConstants.USER_METRIC_REQUEST, requestData } }
function userMetricSuccess(data) { return { type: analysisConstants.USER_METRIC_SUCCESS, data } }
function userMetricFailure(error) { return { type: analysisConstants.USER_METRIC_FAILURE, error } }

function userFollowersHistoryRequest(requestData) { return { type: analysisConstants.USER_FOLLOWERS_HISTORY_REQUEST, requestData } }
function userFollowersHistorySuccess(data) { return { type: analysisConstants.USER_FOLLOWERS_HISTORY_SUCCESS, data } }
function userFollowersHistoryFailure(error) { return { type: analysisConstants.USER_FOLLOWERS_HISTORY_FAILURE, error } }

function mediaMetricRequest(requestData) { return { type: analysisConstants.MEDIA_METRIC_REQUEST, requestData } }
function mediaMetricSuccess(data) { return { type: analysisConstants.MEDIA_METRIC_SUCCESS, data } }
function mediaMetricFailure(error) { return { type: analysisConstants.MEDIA_METRIC_FAILURE, error } }

function mediaLikesHistoryRequest(requestData) { return { type: analysisConstants.MEDIA_LIKES_HISTORY_REQUEST, requestData } }
function mediaLikesHistorySuccess(data) { return { type: analysisConstants.MEDIA_LIKES_HISTORY_SUCCESS, data } }
function mediaLikesHistoryFailure(error) { return { type: analysisConstants.MEDIA_LIKES_HISTORY_FAILURE, error } }

function mediaCommentHistoryRequest(requestData) { return { type: analysisConstants.MEDIA_COMMENTS_HISTORY_REQUEST, requestData } }
function mediaCommentHistorySuccess(data) { return { type: analysisConstants.MEDIA_COMMENTS_HISTORY_SUCCESS, data } }
function mediaCommentHistoryFailure(error) { return { type: analysisConstants.MEDIA_COMMENTS_HISTORY_FAILURE, error } }

function mediaMostActionsListRequest(requestData) { return { type: analysisConstants.MEDIA_MOST_ACTIONS_LIST_REQUEST, requestData } }
function mediaMostActionsListSuccess(data) { return { type: analysisConstants.MEDIA_MOST_ACTIONS_LIST_SUCCESS, data } }
function mediaMostActionsListFailure(error) { return { type: analysisConstants.MEDIA_MOST_ACTIONS_LIST_FAILURE, error } }

function storyMetricRequest(requestData) { return { type: analysisConstants.STORY_METRIC_REQUEST, requestData } }
function storyMetricSuccess(data) { return { type: analysisConstants.STORY_METRIC_SUCCESS, data } }
function storyMetricFailure(error) { return { type: analysisConstants.STORY_METRIC_FAILURE, error } }

function storyReachHistoryRequest(requestData) { return { type: analysisConstants.STORY_REACH_HISTORY_REQUEST, requestData } }
function storyReachHistorySuccess(data) { return { type: analysisConstants.STORY_REACH_HISTORY_SUCCESS, data } }
function storyReachHistoryFailure(error) { return { type: analysisConstants.STORY_REACH_HISTORY_FAILURE, error } }

function storyImpressionHistoryRequest(requestData) { return { type: analysisConstants.STORY_IMPRESSION_HISTORY_REQUEST, requestData } }
function storyImpressionHistorySuccess(data) { return { type: analysisConstants.STORY_IMPRESSION_HISTORY_SUCCESS, data } }
function storyImpressionHistoryFailure(error) { return { type: analysisConstants.STORY_IMPRESSION_HISTORY_FAILURE, error } }
