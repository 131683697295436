import React from 'react';
import './index.css';
import { Link } from "react-router-dom";

export default (props) => {
  const defaultInlineStyles = {}

  const mergedInlineStyles = Object.assign(defaultInlineStyles, props.style)

  if (props.isLink) {
    return (
      <Link className={`igb-button ${props.className}`} disabled={props.disabled} to={props.linkTo} style={mergedInlineStyles} >{ props.children }</Link>
    )
  }
  return (
    <button type={props.buttonType || 'button'} disabled={props.disabled} onClick={props.onClick} className={`igb-button ${props.className}`} style={mergedInlineStyles}>
      {props.children}
    </button>
  )
}
